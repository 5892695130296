import {Component} from '@angular/core';
import {AsyncPipe, NgOptimizedImage} from '@angular/common';

import {UserProfileService} from '../user-profile.service';

@Component({
  selector: 'app-organization-avatar',
  template: `
    @if (userProfileService.userProfile?.organization?.avatar; as avatar) {
      <img alt="logo" class="mr-2" height="30" width="30" [ngSrc]="avatar"/>
    }
  `,
  imports: [
    NgOptimizedImage,
    AsyncPipe
  ],
  standalone: true
})
export class OrganizationAvatarComponent {
  constructor(protected userProfileService: UserProfileService) {}
}
