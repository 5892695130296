import {Component, Inject} from '@angular/core';
import { CommonFormControlIsArchived, CommonFormControlName } from '../../common/common-controls-wrappers';
import {
  AbstractFieldFormControl,
  FormControlWrapperComponent
} from '../../../../shared/components/entity-editor/form-control-wrapper.component';
import {
  IOrganization,
  OrganizationsResourceService,
  TOrganizationSave
} from '../../../resources/organizations-resource.service';
import { inheritanceProvider } from '../../../../shared/util/util';
import { AbstractEntityEditorBase } from '../../../../shared/components/entity-editor/abstract-entity-editor-base';
import { NgClass, NgIf } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SpinnerizerDirective } from '../../../../shared/components/spinnerizer.component';
import { AbstractEntityEditor } from '../../../../shared/components/entity-editor/abstract-entity-editor';
import { TControlsOf } from '../../../../shared/types';
import {emailValidator, existsAsyncValidator, patternValidator} from '../../../../shared/util/validators';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {AvatarComponent} from '../../../../shared/components/avatar.component';
import {COMMON_LIB_CONFIG_TOKEN, ICommonLibConfig} from '../../../../shared/common-lib-config';
import {Validator} from 'class-validator';

@Component({
  selector: 'app-organization-form-control',
  template: `
    @switch (field) {
      @case ('name') {
        <app-fc-name/>
      }
      @case ('displayName') {
        <app-form-control-wrapper [controlName]="field"
                                  [label]="'app.entities.organization.fields.displayName' | translate"></app-form-control-wrapper>
      }
      @case ('email') {
        <app-form-control-wrapper [controlName]="field"
                                  [label]="'app.entities.organization.fields.ownerEmail' | translate"></app-form-control-wrapper>
      }
      @case ('countryCode') {
        <app-form-control-wrapper [controlName]="field"
                                  [label]="'app.entities.organization.fields.dataRegion' | translate"
                                  controlType="dropdown" onDemandOptions="countries" optionLabel="name" optionValue="code" optionIcon="flag"
                                  [optionsFilter]="true">
        </app-form-control-wrapper>
      }
      @case ('useMFA') {
        <app-form-control-wrapper [controlName]="field" dataType="boolean"
                                  [label]="'app.entities.organization.fields.useMFA' | translate"></app-form-control-wrapper>
      }

      @case ('isArchived') {
        <app-fc-isArchived/>
      }
    }
  `,
  standalone: true,
  imports: [CommonFormControlName, CommonFormControlIsArchived, FormControlWrapperComponent, TranslateModule]
})
export class OrganizationFormControlComponent extends AbstractFieldFormControl<TOrganizationSave> {
}

@Component({
  selector: 'app-organization-editor',
  template: `
    <div [spinnerizer]="loading">
      <form *ngIf="form" [formGroup]="form">
        <div class="formgrid grid">
          <div class="field p-fluid" class="field col-12 p-fluid flex justify-content-center">
            <app-avatar formControlName="avatar"
                        [editable]="true" size="70px" fontSize="50px"
                        [name]="(form.controls['name'].value || '')"></app-avatar>
          </div>
          <div class="field p-fluid" class="field col-12 p-fluid">
            <app-organization-form-control field="name"></app-organization-form-control>
          </div>
          <div class="field p-fluid" class="field col-12 p-fluid">
            <app-organization-form-control field="displayName"></app-organization-form-control>
          </div>
          <div class="field p-fluid" class="field col-12 p-fluid">
            <app-organization-form-control field="email"></app-organization-form-control>
          </div>
          <div class="field p-fluid" class="field col-12 p-fluid">
            <app-organization-form-control field="countryCode"></app-organization-form-control>
          </div>
          <div class="field p-fluid" class="field col-12 p-fluid flex align-items-center">
            <app-organization-form-control class="flex-1" field="useMFA"></app-organization-form-control>
            <app-organization-form-control class="flex-1" *ngIf="!isNew()"field="isArchived"></app-organization-form-control>

          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    inheritanceProvider(AbstractEntityEditorBase, OrganizationEditorComponent)
  ],
  standalone: true,
  imports: [
    NgIf, FormsModule, ReactiveFormsModule, NgClass,
    SpinnerizerDirective, OrganizationFormControlComponent, AvatarComponent
  ]
})
export class OrganizationEditorComponent extends AbstractEntityEditor<TOrganizationSave, IOrganization> {

  constructor(public organizationsResource: OrganizationsResourceService) {
    super(organizationsResource, 'app.entities.organization.name');
  }

  override buildForm(): void {
    const checkExistsName = this.getCheckExists(this.organizationsResource.searchEntities.bind(this.organizationsResource), 'name');
    this.form = this.fb.group<TControlsOf<TOrganizationSave>>({
      avatar: new FormControl(this.entity!.avatar, []),
      name: new FormControl(this.entity!.name, {
        nonNullable: true,
        validators: [Validators.required, patternValidator(/^[a-z0-9][a-z0-9-_]*$/, this.translate.instant('app.entities.organization.nameValidatorMessage'))],
        asyncValidators: [/*existsAsyncValidator(checkExistsName)*/] // TODO: uncomment when real api is used
      }),
      displayName: new FormControl(this.entity!.displayName, [Validators.required]),
      email: new FormControl(this.entity!.email, [Validators.required, emailValidator()]),
      countryCode: new FormControl(this.entity!.countryCode, [Validators.required]),
      useMFA: new FormControl(this.entity!.useMFA, [Validators.required]),
      isArchived: new FormControl(this.isNew() ? false : this.entity!.isArchived, [Validators.required]),
    });
  }
}
