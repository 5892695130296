import {Component} from '@angular/core';
import {HolidayCalendarsTableComponent} from './holiday-calendars-table.component';
import {FilterByQueryParamsDirective} from '../../../../../shared/components/table/filter-by-query-params.directive';
import {SharedModule} from 'primeng/api';
import {CommonPageComponent} from '../../../../../shared/components/page/common-page.component';

@Component({
  selector: 'app-holiday-calendars-page',
  template: `
    <app-common-page entityName="app.entities.holidayCalendar.name" onDemandCountMethod="holidayCalendarsCount"
                     stateKey="holidayCalendars">
      <ng-template pTemplate="content">
        <app-holiday-calendars-table></app-holiday-calendars-table>
      </ng-template>
    </app-common-page>
  `,
  standalone: true,
  imports: [CommonPageComponent, SharedModule, HolidayCalendarsTableComponent, FilterByQueryParamsDirective],
})
export class HolidayCalendarsPageComponent {
}
