import {Component} from '@angular/core';
import {TSkillSave} from '../../../../../api/shared/app-domain/dictionaries';
import {
  AbstractFieldFormControl,
  FormControlWrapperComponent
} from '../../../../../shared/components/entity-editor/form-control-wrapper.component';
import {inheritanceProvider} from '../../../../../shared/util/util';
import {OptionsPipe} from '../../../../../shared/components/options.pipe';
import {AsyncPipe} from '@angular/common';
import {CommonFormControlIsArchived, CommonFormControlName} from '../../../common/common-controls-wrappers';
import {TranslateModule} from '@ngx-translate/core';


@Component({
  selector: 'app-skills-form-control',
  template: `
    @switch (field) {
      @case ('name') {
        <app-fc-name></app-fc-name>
      }
      @case ('isArchived') {
        <app-fc-isArchived></app-fc-isArchived>
      }
      @case ('silo') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.silo' | translate"
                                  controlType="dropdown" [dropdownEditable]="true"
                                  onDemandOptions="skillSilos"></app-form-control-wrapper>
      }
      @case ('isForReview') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.isForReview' | translate" dataType="boolean"></app-form-control-wrapper>
      }
      @case ('urlSlug') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.urlSlug' | translate"></app-form-control-wrapper>
      }
      @case ('metaDescriptions') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.metaDescriptions' | translate" dataType="text"></app-form-control-wrapper>
      }
      @case ('isOkToPublish') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.isOkToPublish' | translate" dataType="boolean"></app-form-control-wrapper>
      }
      @case ('adjective') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.adjective' | translate"></app-form-control-wrapper>
      }
      @case ('singular') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.singular' | translate"></app-form-control-wrapper>
      }
      @case ('plural') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.plural' | translate"></app-form-control-wrapper>
      }
      @case ('team') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.team' | translate"></app-form-control-wrapper>
      }
      @case ('teams') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.teams' | translate"></app-form-control-wrapper>
      }
      @case ('siloMember') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.siloMember' | translate"></app-form-control-wrapper>
      }
      @case ('siloMembers') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.siloMembers' | translate"></app-form-control-wrapper>
      }
      @case ('metaTitle') {
        <app-form-control-wrapper [label]="'app.entities.skill.fields.metaTitle' | translate"></app-form-control-wrapper>
      }
      @default {
        <app-form-control-wrapper></app-form-control-wrapper>
      }
    }
  `,
  providers: [
    inheritanceProvider(AbstractFieldFormControl, SkillsFormControlComponent)
  ],
  standalone: true,
  imports: [
    CommonFormControlIsArchived,
    FormControlWrapperComponent,
    AsyncPipe,
    OptionsPipe,
    TranslateModule,
    CommonFormControlName,
  ],
})
export class SkillsFormControlComponent extends AbstractFieldFormControl<TSkillSave> {
}

