import {Component} from '@angular/core';
import {IExpenseCategory, TExpenseCategorySave} from '../../../../api/shared/app-domain/dictionaries';
import {inheritanceProvider} from '../../../../shared/util/util';
import {ColumnHeaderComponent} from '../../../../shared/components/table/column-header.component';
import {AbstractEntityEditorBase} from '../../../../shared/components/entity-editor/abstract-entity-editor-base';
import {AbstractEntityEditor} from '../../../../shared/components/entity-editor/abstract-entity-editor';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {TControlsOf} from '../../../../shared/types';
import {ExpenseCategoriesResourceService} from '../../../resources/dictionaries/expense-categories-resource.service';
import {
  AbstractFieldFormControl,
  FormControlWrapperComponent
} from '../../../../shared/components/entity-editor/form-control-wrapper.component';
import {
  AbstractEntityBulkEditor,
  EntityBulkEditorFormComponent
} from '../../../../shared/components/entity-editor/abstract-entity-bulk-editor';
import {existsAsyncValidator} from '../../../../shared/util/validators';
import {NgClass, NgIf} from '@angular/common';
import {TableCellComponent} from '../../../../shared/components/table/table-cell.component';
import {
  CommonColumnHeaderIsArchived,
  CommonFormControlIsArchived,
  CommonFormControlName,
  CommonTableCellIsArchived,
  CommonTableCellName
} from '../../common/common-controls-wrappers';
import {SpinnerizerDirective} from '../../../../shared/components/spinnerizer.component';
import {SharedModule} from 'primeng/api';
import {EntityEditDialogComponent} from '../../../../shared/components/entity-editor/entity-edit.dialog';
import {CommonPageComponent} from '../../../../shared/components/page/common-page.component';
import {EntityTableComponent} from '../../../../shared/components/table/entity-table.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-expense-category-form-control',
  template: `
    @switch (field) {
      @case ('name') {
        <app-fc-name/>
      }
      @case ('isArchived') {
        <app-fc-isArchived/>
      }
      @case ('accountingCode') {
        <app-form-control-wrapper [controlName]="field" [label]="'app.entities.expenseCategory.fields.accountingCode' | translate"></app-form-control-wrapper>
      }
      @case ('isBillable') {
        <app-form-control-wrapper [controlName]="field" [label]="'app.entities.expenseCategory.fields.isBillable' | translate"
                                  dataType="boolean"></app-form-control-wrapper>
      }
    }
  `,
  standalone: true,
  imports: [CommonFormControlName, CommonFormControlIsArchived, FormControlWrapperComponent, TranslateModule]
})
export class ExpenseCategoryFormControlComponent extends AbstractFieldFormControl<TExpenseCategorySave> {
}

@Component({
  selector: 'app-expense-category-editor',
  template: `
    <div [spinnerizer]="loading">
      <form *ngIf="form" [formGroup]="form">
        <div class="formgrid grid">
          <div class="field p-fluid" [ngClass]="isNew() ? 'col-12' : 'col-8'">
            <app-expense-category-form-control field="name"></app-expense-category-form-control>
          </div>
          <div *ngIf="!isNew()" class="field col-4 p-fluid">
            <app-expense-category-form-control field="isArchived"></app-expense-category-form-control>
          </div>
          <div class="field col-8 p-fluid">
            <app-expense-category-form-control field="accountingCode"></app-expense-category-form-control>
          </div>
          <div class="field col-4 p-fluid pt-5">
            <app-expense-category-form-control field="isBillable"></app-expense-category-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    inheritanceProvider(AbstractEntityEditorBase, ExpenseCategoryEditorComponent)
  ],
  standalone: true,
  imports: [
    NgIf, FormsModule, ReactiveFormsModule, NgClass, ExpenseCategoryFormControlComponent,
    SpinnerizerDirective
  ]
})
export class ExpenseCategoryEditorComponent extends AbstractEntityEditor<TExpenseCategorySave, IExpenseCategory> {

  constructor(public expenseCategoriesResource: ExpenseCategoriesResourceService) {
    super(expenseCategoriesResource, 'app.entities.expenseCategory.name');
  }

  override buildForm(): void {
    const checkExistsName = this.getCheckExists(this.expenseCategoriesResource.searchEntities.bind(this.expenseCategoriesResource), 'name');
    this.form = this.fb.group<TControlsOf<TExpenseCategorySave>>({
      name: new FormControl(this.entity!.name, {
        nonNullable: true,
        validators: [Validators.required],
        asyncValidators: [existsAsyncValidator(checkExistsName)]
      }),
      accountingCode: new FormControl(this.entity!.accountingCode, [Validators.required]),
      isBillable: new FormControl(this.isNew() ? true : this.entity!.isBillable),
      isArchived: new FormControl(this.isNew() ? false : this.entity!.isArchived, [Validators.required]),
    });
  }
}


@Component({
  selector: 'app-expense-category-bulkeditor',
  template: `
    <app-entity-bulk-editor-form>
      <ng-template pTemplate let-field>
        <form [formGroup]="form!">
          <app-expense-category-form-control [field]="field">
          </app-expense-category-form-control>
        </form>
      </ng-template>
    </app-entity-bulk-editor-form>
  `,
  providers: [
    inheritanceProvider(AbstractEntityEditorBase, ExpenseCategoryBulkEditorComponent)
  ],
  standalone: true,
  imports: [
    EntityBulkEditorFormComponent, SharedModule, FormsModule, ReactiveFormsModule, ExpenseCategoryFormControlComponent
  ]
})
export class ExpenseCategoryBulkEditorComponent extends AbstractEntityBulkEditor<TExpenseCategorySave, IExpenseCategory> {
  constructor(public expenseCategoriesResource: ExpenseCategoriesResourceService, translate: TranslateService) {
    super();
    this.entityName = 'app.entities.expenseCategory.countable';
    this.api = expenseCategoriesResource;
    this.fieldDescriptors = {
      isBillable: {label: translate.instant('app.entities.expenseCategory.fields.isBillable'), defaultValue: false, validators: [Validators.required]},
      isArchived: {label: translate.instant('app.entities.common.fields.isArchived'), validators: [Validators.required]}
    };
  }
}

@Component({
  selector: 'app-expense-categories-table',
  template: `
    <app-entity-edit-dialog #editDialog>
      <ng-template pTemplate let-param>
        <app-expense-category-editor [param]="param"></app-expense-category-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-entity-edit-dialog #bulkDialog width="500px">
      <ng-template pTemplate let-param>
        <app-expense-category-bulkeditor [param]="param"></app-expense-category-bulkeditor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-entity-table #entityTable
                      [api]="expenseCategoriesResource" [defaultSort]="[{field: 'name'}]"
                      [pageable]="false"
                      [editDialog]="editDialog" [bulkEditDialog]="bulkDialog">
      <ng-template pTemplate="$header">
        <app-th field="name" [label]="'app.entities.common.fields.name' | translate" filterType="text">
        </app-th>
        <app-th field="accountingCode" [label]="'app.entities.expenseCategory.fields.accountingCode' | translate" filterType="text">
        </app-th>
        <app-th field="isBillable" [label]="'app.entities.expenseCategory.fields.isBillable' | translate" filterType="boolean">
        </app-th>
        <app-th-isArchived/>
      </ng-template>
      <ng-template pTemplate="$body" let-rowData>
        <app-td-name [rowData]="rowData" [omitMenuItems]="['duplicate']" [tdStyle]="{minWidth: '400px'}"
                     (onClick)="entityTable.openCreateOrUpdate(rowData)"/>
        <app-td field="accountingCode" [rowData]="rowData"></app-td>
        <app-td field="isBillable" [rowData]="rowData" type="boolean"></app-td>
        <app-td-isArchived [rowData]="rowData"/>
      </ng-template>
    </app-entity-table>
  `,
  standalone: true,
  imports: [
    EntityEditDialogComponent,
    SharedModule,
    ExpenseCategoryEditorComponent,
    ExpenseCategoryBulkEditorComponent,
    ColumnHeaderComponent,
    EntityTableComponent,
    CommonColumnHeaderIsArchived,
    CommonTableCellName,
    TableCellComponent,
    CommonTableCellIsArchived,
    TranslateModule
  ]
})
export class ExpenseCategoriesTableComponent {
  constructor(public expenseCategoriesResource: ExpenseCategoriesResourceService) {
  }
}

@Component({
  template: `
    <app-common-page entityName="app.entities.expenseCategory.name" onDemandCountMethod="expenseCategoriesCount">
      <ng-template pTemplate="content">
        <app-expense-categories-table></app-expense-categories-table>
      </ng-template>
    </app-common-page>
  `,
  standalone: true,
  imports: [
    CommonPageComponent,
    ExpenseCategoriesTableComponent,
    SharedModule
  ]
})
export class ExpenseCategoriesPageComponent {
}
