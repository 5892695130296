import {MenuItem} from 'primeng/api';
import {ILocalized} from '../../../shared/types';
import {IPermissible} from '../../../shared/auth-provider/permissions';


export const MAIN_MENU: Array<MenuItem & IPermissible> = [
  {
    label: 'Time & Expenses',
    items: [
      {
        label: 'Time',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Time Sheets',
        routerLink: ['/time-sheets'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Time Entries',
        routerLink: ['/time-entries'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Time Approvals',
        routerLink: ['/time-approvals'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Expenses',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Expenses',
        routerLink: ['/expenses'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Expense Reports',
        routerLink: ['/expense-reports'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Expense Approvals',
        routerLink: ['/expense-approvals'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Schedule',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Schedule',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Workweek Settings',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Holiday Calendar',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Time Off',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Time Off Requests',
        routerLink: ['/time-off-requests'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Annual Log',
        routerLink: ['/annual-log'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Time Off Approvals',
        routerLink: ['/time-off-approvals'],
        styleClass: 'mt-main-menu-indented-item'
      }
    ]
  },
  {
    label: 'Team',
    items: [
      {
        label: 'Team',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'All Team',
        routerLink: ['/team'],
        queryParams: {
          scope: 'all',
          status: 'Active'
        },
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'By Talent Partner',
        routerLink: ['/team'],
        queryParams: {
          scope: 'talentPartner',
          status: 'Active'
        },
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Candidates',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'All Candidates',
        routerLink: ['/team-candidates'],
        queryParams: {
          scope: 'all'
        },
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'By Talent Partner',
        routerLink: ['/team-candidates'],
        queryParams: {
          scope: 'talentPartner'
        },
        styleClass: 'mt-main-menu-indented-item'
      }
    ]
  },
  // {
  //   label: 'Assignments',
  //   items: [
  //   ]
  // },
  {
    label: 'Projects',
    items: [
      {
        label: 'Projects',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'All Projects',
        routerLink: ['/projects'],
        queryParams: {
          scope: 'all',
          status: 'Active'
        },
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'By Client',
        routerLink: ['/projects'],
        queryParams: {
          scope: 'client',
          status: 'Active'
        },
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'By Agency',
        routerLink: ['/projects'],
        queryParams: {
          scope: 'agency',
          status: 'Active'
        },
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Pending',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Projects Pending',
        routerLink: ['/projects'],
        styleClass: 'mt-main-menu-indented-item',
        queryParams: {
          mode: 'all',
          status: 'Pending'
        }
      },
      {
        label: 'Quoted',
        routerLink: ['/projects'],
        styleClass: 'mt-main-menu-indented-item',
        queryParams: {
          mode: 'all',
          status: 'Quoted'
        }
      },
      {
        separator: true
      },
      {
        label: 'Archived',
        routerLink: ['/projects'],
        queryParams: {
          mode: 'all',
          status: 'Archived'
        }
      }
    ]
  },
  {
    label: 'Billing',
    items: [
      {
        label: 'Billing',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Invoices',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Payments',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Preferences',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Payments',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Invoices',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Fixed Payroll',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Commissions',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Payments',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Preferences',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      }
    ]
  },
  {
    label: 'Companies',
    items: [
      {
        label: 'All Companies',
        routerLink: ['/companies'],
        queryParams: {
          // colFilter_status: 'Active' as TCompanyStatus
        }
      },
      {
        label: 'Clients',
        routerLink: ['/companies'],
        queryParams: {
          // colFilter_types: 'Client' as TCompanyType,
          // colFilter_status: 'Active' as TCompanyStatus
        }
      },
      {
        label: 'Agency Resellers',
        routerLink: ['/companies'],
        queryParams: {
          // colFilter_types: 'Agency Reseller' as TCompanyType,
          // colFilter_status: 'Active' as TCompanyStatus
        }
      },
      {
        label: 'Talent Partners',
        routerLink: ['/companies'],
        queryParams: {
          // colFilter_types: 'Talent Partner' as TCompanyType,
          // colFilter_status: 'Active' as TCompanyStatus
        }
      },
      {
        label: 'Sales Rep Companies',
        routerLink: ['/companies'],
        queryParams: {
          // colFilter_types: 'Sales Rep' as TCompanyType,
          // colFilter_status: 'Active' as TCompanyStatus
        }
      },
      {
        label: 'Referral Partners',
        routerLink: ['/companies'],
        queryParams: {
          // colFilter_types: 'Referral Partner' as TCompanyType,
          // colFilter_status: 'Active' as TCompanyStatus
        }
      }
    ]
  },
  {
    label: 'Contacts',
    items: [
      {
        label: 'All Contacts',
        routerLink: ['/contacts']
      },
      {
        label: 'Team Members',
        routerLink: ['/contacts'],
        queryParams: {
          // colFilter_types: 'Team Member' as TContactType,
        }
      },
      {
        label: 'Team Candidates',
        routerLink: ['/contacts'],
        queryParams: {
          // colFilter_types: 'Team Candidate' as TContactType,
        }
      },
      {
        label: 'Sales Reps',
        routerLink: ['/contacts'],
        queryParams: {
          // colFilter_types: 'Sales Rep' as TContactType,
        }
      },
      {
        label: 'Affiliates',
        routerLink: ['/contacts'],
        queryParams: {
          // colFilter_types: 'Affiliate' as TContactType,
        }
      },
      {
        label: 'Clients',
        routerLink: ['/contacts'],
        queryParams: {
          // colFilter_types: 'Client' as TContactType,
        }
      },
      {
        label: 'Agency Resellers',
        routerLink: ['/contacts'],
        queryParams: {
          // colFilter_types: 'Agency Reseller' as TContactType,
        }
      },
      {
        label: 'Talent Partners',
        routerLink: ['/contacts'],
        queryParams: {
          // colFilter_types: 'Talent Partner' as TContactType,
        }
      },
      {
        label: 'Referral Partners',
        routerLink: ['/contacts'],
        queryParams: {
          // colFilter_types: 'Referral Partner' as TContactType,
        }
      }
    ]
  },
  {
    label: 'Users',
    items: [
      {
        label: 'All Users',
        routerLink: ['/tbd']
      }
    ]
  },
  {
    label: 'Referrals',
    items: [
      {
        label: 'Project Referrals',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'All Referrals',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'By Referral Partner',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Expected Commissions',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },

      {
        separator: true
      },
      {
        label: 'Sales Reps',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'All Sales Reps',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'By Sales Rep Company',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Expected Commissions',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },

      {
        separator: true
      },
      {
        label: 'Team Referrals',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'All Team Referrals',
        routerLink: ['/referrals'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        separator: true
      },
      {
        label: 'Promo & Sales Collateral',
        disabled: true,
        styleClass: 'mt-main-menu-header'
      },
      {
        label: 'Share Online',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      },
      {
        label: 'Campaign Assets',
        routerLink: ['/tbd'],
        styleClass: 'mt-main-menu-indented-item'
      }
    ]
  },
  {
    label: 'Reports',
    items: [
      {
        label: 'Time',
        routerLink: ['/tbd']
      },
      {
        label: 'Transactions',
        routerLink: ['/tbd']
      }
    ]
  },

];


export const USER_MENU: Array<MenuItem & ILocalized & IPermissible> = [
  {
    id: 'manage-your-account',
    i18n: 'app.userMenu.manageYourAccount',
    icon: 'pi pi-fw pi-user',
    routerLink: '/tbd',
  },
  {
    id: 'settings',
    i18n: 'app.userMenu.settings',
    icon: 'pi pi-fw pi-cog',
    routerLink: '/app-settings'
  },
  {
    id: 'organizations',
    i18n: 'app.userMenu.organizations',
    icon: 'pi pi-fw pi-building-columns',
    routerLink: '/organizations',
    permissionExpr: 'TENANT_ALL',
  },
  {
    id: 'referrals',
    i18n: 'app.userMenu.referrals',
    icon: 'pi pi-fw pi-users',
    routerLink: '/tbd'
  },
  {
    id: 'sign-out',
    i18n: 'app.userMenu.signOut',
    icon: 'pi pi-fw pi-sign-out'
  },
  {
    id: 'switch-workspace-sep',
    separator: true
  },
  {
    id: 'switch-workspace',
    i18n: 'app.userMenu.organizationSwitch',
    icon: 'pi pi-fw pi-sort-alt'
  }
];

export const SETTINGS_MENU: Array<MenuItem & ILocalized & IPermissible> = [
  {
    i18n: 'app.settingsMenu.workspaceSettings',
    routerLink: '/tbd'
  },
  {
    i18n: 'app.settingsMenu.companySettings',
    routerLink: '/tbd'
  },
  {
    separator: true
  },
  {
    i18n: 'app.settingsMenu.schedule',
    disabled: true,
    styleClass: 'mt-main-menu-header'
  },
  {
    i18n: 'app.settingsMenu.holidayCalendars',
    routerLink: ['settings/holiday-calendars'],
    styleClass: 'mt-main-menu-indented-item'
  },
  {
    i18n: 'app.settingsMenu.holidaysUniverse',
    routerLink: ['settings/holidays'],
    styleClass: 'mt-main-menu-indented-item'
  },
  {
    i18n: 'app.settingsMenu.workweeks',
    routerLink: ['settings/workweeks'],
    styleClass: 'mt-main-menu-indented-item'
  },
  {
    i18n: 'app.settingsMenu.paidTimeOffs',
    routerLink: ['settings/paid-time-offs'],
    styleClass: 'mt-main-menu-indented-item'
  },
  {
    separator: true
  },
  {
    i18n: 'app.settingsMenu.departments',
    routerLink: '/settings/departments'
  },
  {
    i18n: 'app.settingsMenu.roles',
    routerLink: '/settings/roles'
  },
  {
    i18n: 'app.settingsMenu.skills',
    routerLink: '/settings/skills'
  },
  {
    i18n: 'app.settingsMenu.expenseCategories',
    routerLink: '/settings/expense-categories'
  },
  {
    separator: true
  },
  {
    i18n: 'app.settingsMenu.systemRoles',
    routerLink: '/settings/system/roles'
  },
  {
    i18n: 'app.settingsMenu.systemTokens',
    routerLink: '/settings/system/tokens'
  },
  {
    separator: true
  },
  {
    i18n: 'app.settingsMenu.tax',
    routerLink: '/tbd'
  },
  {
    i18n: 'app.settingsMenu.termsAndConditions',
    routerLink: '/tbd'
  },
  {
    i18n: 'app.settingsMenu.quotes',
    routerLink: '/tbd'
  }

];

