import {Component, effect, input, OnInit, ViewChild} from '@angular/core';
import {Router, RouterLink} from '@angular/router';
import {finalize} from 'rxjs';
import {SharedModule, TreeNode} from 'primeng/api';
import {AsyncPipe, DatePipe, NgForOf, NgIf} from '@angular/common';
import {find, forEach, isEqual, sumBy} from 'lodash';
import {TreeTable, TreeTableModule} from 'primeng/treetable';
import {IPeriod} from '../../../../../api/shared/common';
import {getCurrentPeriodValue, PeriodChooserComponent} from '../../../../../shared/components/period-chooser.component';
import {
  OnDemandResourceLoaderService
} from '../../../../../shared/services/resources/on-demand-resource-loader.service';
import {HolidayCalendarsResourceService} from '../../../../resources/dictionaries/holiday-calendars-resource.service';
import {DEFAULT_CALENDAR_YEAR_RANGE} from './calendar';
import {TabViewModule} from 'primeng/tabview';
import {MultiSelectModule} from 'primeng/multiselect';
import {ChangesDebounceDirective} from '../../../../../shared/components/changes-debounce.directive';
import {NullableDirective} from '../../../../../shared/components/nullable.directive';
import {InputTextModule} from 'primeng/inputtext';
import {SpinnerizerDirective} from '../../../../../shared/components/spinnerizer.component';
import {FormsModule} from '@angular/forms';
import {CommonPageComponent} from '../../../../../shared/components/page/common-page.component';
import {StateAdapter} from '../../../../../shared/services/state-adapter';
import {FilterToQueryParamsPipe} from '../../../../../shared/components/table/table-query';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-holiday-universe-table',
  template: `
    <div class="mt-table-container">
      <div [spinnerizer]="loading" [text]="'app.entities.holidaysUniverse.loadingHolidays' | translate">
        <p-treeTable [value]="data" [scrollable]="true" scrollHeight="calc(100vh - 300px)">
          <ng-template pTemplate="header">
            <tr>
              <th>{{ 'app.entities.holidaysUniverse.dateOrHoliday' | translate }}</th>
              <th>{{ 'app.entities.holidaysUniverse.numberOfHolidays' | translate }}</th>
              <th>{{ 'app.entities.holidaysUniverse.numberOfCountriesAndCalendars' | translate }}</th>
            </tr>
            <tr>
              <th class="p-fluid">
              <span class="p-input-icon-left p-input-icon-right">
                <i class="pi pi-search"></i>
                <i class="pi pi-times cursor-pointer" (click)="holidayModel.control.setValue(null)"></i>
                <input #holidayModel="ngModel" pInputText [(ngModel)]="filters.holidayName"
                       appChangesDebounce (debounced)="saveState(); filter()" nullable
                       [placeholder]="'app.entities.holidaysUniverse.searchHoliday' | translate"/>
              </span>
              </th>
              <th></th>
              <th class="p-fluid flex align-items-center">
                <div class="mr-1 flex-1">
                  <p-multiSelect [(ngModel)]="filters.countries"
                                 [options]="$any(onDemandLoader.observe('countries') | async)"
                                 optionLabel="name"
                                 optionValue="code"
                                 [showToggleAll]="true"
                                 [placeholder]="'app.entities.holidaysUniverse.selectCountries' | translate"
                                 appendTo="body"
                                 [showClear]="true"
                                 (ngModelChange)="saveState(); filter()">
                    <ng-template let-item pTemplate="item">
                      <div class="flex align-items-center">
                        <img class="mr-2" [src]="item.flag" width="24"/>
                        <div>{{ item.name }}</div>
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </div>
                <div class="ml-1 flex-1">
                <span class="p-input-icon-right">
                  <i class="pi pi-times cursor-pointer" (click)="calendarModel.control.setValue(null)"></i>
                  <input #calendarModel="ngModel" pInputText [(ngModel)]="filters.calendars"
                         appChangesDebounce (debounced)="saveState(); filter()" nullable
                         [placeholder]="'app.entities.holidaysUniverse.searchCalendar' | translate"/>
                </span>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr [ttRow]="rowNode" *ngIf="!rowData.isCountries"
                [class.expanded]="rowData.isDay && rowNode.node.expanded">
              <td>
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                <span [class.font-bold]="rowNode.node.expanded">{{ rowData.name }}</span>
              </td>
              <td>
                {{ rowData.count }}
              </td>
              <td>
                {{ rowData.countries }} / {{ rowData.calendars }}
              </td>
            </tr>
            <tr [ttRow]="rowNode" *ngIf="rowData.isCountries">
              <td colspan="3">
                <div class="ml-8">
                  <p-tabView>
                    <p-tabPanel [header]="'app.entities.holidaysUniverse.countries' | translate">
                      <div class="flex flex-wrap">
                        <a *ngFor="let country of rowData.countries"
                           class="chip inline-flex align-items-center mt-link"
                           [routerLink]="['/settings/holiday-calendars']"
                           [queryParams]="{countryCode: { value: [country.code], matchMode: 'in'}} | filterToQueryParams:'holidayCalendars.table'">
                          <img class="mr-2" [src]="country.flag" width="16">{{ country.name }}
                        </a>
                      </div>
                    </p-tabPanel>
                    <p-tabPanel [header]="'app.entities.holidaysUniverse.calendars' | translate">
                      <div class="flex flex-wrap">
                        <a *ngFor="let calendar of rowData.calendars"
                           class="chip calendar-chip inline-flex align-items-center mt-link"
                           [routerLink]="['/settings/holiday-calendars/edit', {id: calendar.id}]">
                          {{ calendar.name }}
                        </a>
                      </div>
                    </p-tabPanel>
                  </p-tabView>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-treeTable>
      </div>
    </div>
  `,
  styles: [
    `
      .chip {
        border-radius: 12px;
        background-color: #DEE2E6;
        padding: 0 10px 0;
        margin: 3px 0 3px;
        margin-right: .5rem;
      }

      .calendar-chip {
        padding: 2px 10px 2px;
      }

      :host ::ng-deep p-treetable table .p-treetable-tbody > tr:nth-child(even) {
        background: #fcfcfc;
      }

      :host ::ng-deep .p-treetable .p-treetable-tbody > tr.expanded {
        // background: #004f79;
        background-image: linear-gradient(to bottom, #416D86 0%, #6EA6C6 100%);
        color: #FFF;
      }

      :host ::ng-deep .p-treetable .p-treetable-tbody > tr.expanded > td .p-treetable-toggler {
        color: #FFF;
      }
    `
  ],
  standalone: true,
  imports: [
    SpinnerizerDirective,
    TreeTableModule,
    FormsModule,
    ChangesDebounceDirective,
    TabViewModule,
    NgForOf,
    MultiSelectModule,
    AsyncPipe,
    RouterLink,
    NullableDirective,
    NgIf,
    InputTextModule,
    FilterToQueryParamsPipe,
    FilterToQueryParamsPipe,
    TranslateModule
  ]
})
export class HolidayUniverseTable implements OnInit {
  selectedPeriod = input.required<IPeriod>();
  @ViewChild(TreeTable) treeTable?: TreeTable;
  loading = false;
  data: Array<TreeNode> = [];
  filters: {
    holidayName?: string;
    countries?: Array<string>;
    calendars?: string;
  } = {};
  stateAdapter = new StateAdapter();

  constructor(public onDemandLoader: OnDemandResourceLoaderService,
              public holidayCalendarsResource: HolidayCalendarsResourceService,
              protected datePipe: DatePipe) {
    effect(() => {
      this.load();
    });
  }

  ngOnInit(): void {
    const state = this.stateAdapter.restoreState<typeof this.filters>('holidays.filters');
    if (state) {
      this.filters = state;
    }
  }

  saveState(): void {
    this.stateAdapter.saveState('holidays.filters', this.filters);
  }

  load(): void {
    this.loading = true;
    this.holidayCalendarsResource.getHolidaysUniverse(this.selectedPeriod().start.getFullYear())
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((response) => {
        const data: Array<TreeNode> = [];
        response.forEach((holidayByDate) => {
          const node: TreeNode = {
            data: {
              isDay: true,
              name: this.datePipe.transform(holidayByDate.date, 'MMM dd'),
              count: holidayByDate.holidays.length,
              countries: sumBy(holidayByDate.holidays, ((dh) => dh.countries.length)),
              calendars: sumBy(holidayByDate.holidays, ((dh) => dh.calendars.length)),
            }
          };
          node.children = holidayByDate.holidays.map((dh) => {
            const nestedNode: TreeNode = {
              data: {
                isHoliday: true,
                name: dh.name,
                count: null,
                countries: dh.countries.length,
                calendars: dh.calendars.length
              }
            };
            nestedNode.children = [
              {
                data: {
                  isCountries: true,
                  name: null,
                  count: null,
                  countries: dh.countries,
                  calendars: dh.calendars
                }
              }
            ];
            return nestedNode;
          });

          data.push(node);
        });
        this.data = data;
        if (this.hasFilter()) {
          this.filter();
        }
      });
  }

  getCount(): number {
    if (this.treeTable?.filteredNodes?.length !== undefined) {
      return this.treeTable?.filteredNodes?.length;
    }
    return this.data.length;
  }


  hasFilter(): boolean {
    return !!this.filters.countries?.length || !!this.filters.calendars || !!this.filters.holidayName;
  }

  filter(): void {
    const filtered: Array<TreeNode> = [];
    if (this.hasFilter()) {
      forEach(this.data, (nDate) => {
        const nHolidays: Array<TreeNode> = [];
        forEach(nDate.children, (nHoliday) => {
          let found = true;
          if (this.filters.countries?.length) {
            if (!find(nHoliday.children![0].data.countries, (c) => this.filters.countries?.includes(c.code))) {
              found = false;
            }
          }
          if (this.filters.calendars) {
            if (!find(nHoliday.children![0].data.calendars,
              (c) => c.name.toLowerCase().includes(this.filters.calendars!.toLowerCase()))) {
              found = false;
            }
          }
          if (this.filters.holidayName) {
            // if (!find(nHoliday!,
            //   (c) => c.data.name.toLowerCase().includes(this.filters.holidayName!.toLowerCase()))) {
            if (!nHoliday.data.name.toLowerCase().includes(this.filters.holidayName!.toLowerCase())) {
              found = false;
            }

          }
          if (found) {
            nHolidays.push(nHoliday);
          }
        })
        if (nHolidays.length) {
          filtered.push({
            ...nDate,
            ...{
              children: nHolidays
            }
          });
        }
      });
    }
    const nodes = this.hasFilter() ? filtered : this.data;
    this.treeTable!.filteredNodes = this.hasFilter() ? filtered : null as any;
    this.treeTable!.tableService.onUIUpdate(nodes);
    this.treeTable!.updateSerializedValue();

    if (this.treeTable!.scrollable) {
      this.treeTable!.resetScrollTop();
    }
  }
}

@Component({
  selector: 'app-holiday-universe-page',
  template: `
    <app-common-page entityName="Calendar"
                     (onPageInit)="onPageInit()"
                     (onNewEntity)="newHoliday()" [showTitle]="false" stateKey="holidays">
      <ng-template pTemplate="topHeader.left">
        <div>
          <div class="mt-page-title">{{'app.entities.holidaysUniverse.name' | translate}}</div>
          <div class="text-gray-600 text-sm">{{'app.entities.holidaysUniverse.holidaysCount' | translate: {count: table?.getCount() || 0} }}</div>
        </div>
      </ng-template>
      <ng-template pTemplate="topHeader.right">
        <app-period-chooser [(ngModel)]="requestParam.period" [unitFilter]="['year']" [showUnits]="false"
                            (ngModelChange)="saveState()"
                            [minDate]="minDate" [maxDate]="maxDate"></app-period-chooser>
      </ng-template>
      <ng-template pTemplate="content">
        <app-holiday-universe-table [selectedPeriod]="requestParam.period"></app-holiday-universe-table>
      </ng-template>
    </app-common-page>
  `,

  standalone: true,
  imports: [
    CommonPageComponent,
    SharedModule,
    PeriodChooserComponent,
    FormsModule,
    ChangesDebounceDirective,
    HolidayUniverseTable,
    TranslateModule
  ]
})
export class HolidaysUniversePageComponent {
  @ViewChild(HolidayUniverseTable) table?: HolidayUniverseTable;
  minDate = new Date(new Date().getFullYear() - DEFAULT_CALENDAR_YEAR_RANGE.backward, 0, 1);
  maxDate = new Date(new Date().getFullYear() + DEFAULT_CALENDAR_YEAR_RANGE.forward, 0, 1);
  requestParam: { period: IPeriod } = {
    period: getCurrentPeriodValue('year')
  };
  stateAdapter = new StateAdapter();

  constructor(private router: Router) {
  }

  onPageInit(): void {
    const state = this.stateAdapter.restoreState<typeof this.requestParam>('holidays.requestParam');
    if (state) {
      this.requestParam = {...this.requestParam, ...state};
    }
  }

  saveState(): void {
    const requestParam: Partial<typeof this.requestParam> = {...this.requestParam};
    if (isEqual(requestParam.period, getCurrentPeriodValue('year'))) {
      delete requestParam.period;
    }
    this.stateAdapter.saveState('holidays.requestParam', requestParam);
  }

  newHoliday() {
    this.router.navigate(['/settings/holiday-calendars/edit']);
  }

}
