import { AfterViewInit, Component, ElementRef, inject, Renderer2 } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'app-alert-bar',
  template: `
    @if (showAlertBar) {
      <div class="grid grid-nogutter w-full">
        <div class="col-6 flex align-items-center">
          <span>10 trial days remaining.</span>
          <a class="ml-2 opacity-80 cursor-pointer no-underline text-white"
             [href]="'/tbd'">View pricing plans.</a>
        </div>
        <div class="col-6 flex justify-content-end align-items-center">
          <a class="ml-2 opacity-80 cursor-pointer no-underline text-white"
             [href]="'/tbd'">Sign up for a free training webinar</a>
          <button pButton pRipple type="button" icon="pi pi-times"
                  class="ml-3 p-button-rounded p-button-text"
                  (click)="hideAlertBar()"></button>
        </div>
      </div>
    }
  `,
  standalone: true,
  host: {
    class: 'mt-alert-bar',
    '[class.hidden]': '!showAlertBar'
  },
  imports: [
    ButtonModule,
    RippleModule
  ]
})
export class AlertBarComponent implements AfterViewInit {
  showAlertBar = true;
  private ref = inject(ElementRef);
  private renderer = inject(Renderer2);
  parentEl: any;

  ngAfterViewInit(): void {
    this.parentEl = this.ref.nativeElement.closest('.mt-app-main');
    if (this.parentEl) {
      this.renderer.addClass(this.parentEl, 'mt-show-alert-bar');
    }
  }

  hideAlertBar(): void {
    this.showAlertBar = false;
    if (this.parentEl) {
      this.renderer.removeClass(this.parentEl, 'mt-show-alert-bar');
    }
  }
}
