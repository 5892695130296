import { APP_INITIALIZER, inject, Injector, NgModule } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Alert } from './util/alert';
import { i18Initializer, i18nProviders, I18nService } from './services/i18n/i18n.service';
import {DatePipe} from '@angular/common';

@NgModule({
  providers: [
    MessageService,
    ConfirmationService,
    i18nProviders(),
    i18Initializer(),
    DatePipe
  ]
})
export class SharedModule {
  constructor() {
    Alert.injector = inject(Injector);
  }
}
