import {Component, Input} from '@angular/core';
import {AbstractControl, FormsModule, NgModel, ValidationErrors} from '@angular/forms';
import {cloneDeep, isEmpty, isEqual, padStart, range, some, sortBy, sum} from 'lodash';
import {DateTime} from 'luxon';
import {AbstractControlValueAccessor} from '../../../../../shared/components/abstract-control-value-accessor';
import {IWorkweek} from '../../../../../api/shared/app-domain/dictionaries';
import {IIdentified} from '../../../../../api/shared/common';
import {ConstantsProviderService} from '../../../../resources/constants-provider.service';
import {Alert} from '../../../../../shared/util/alert';
import {ArrayOfPipe} from '../../../../../shared/components/array-of.pipe';
import {TooltipOnOverflowDirective} from '../../../../../shared/components/tooltip-on-overflow.directive';
import {NullableDirective} from '../../../../../shared/components/nullable.directive';
import {InputTextModule} from 'primeng/inputtext';
import {ControlErrorComponent} from '../../../../../shared/components/control-error.component';
import {ExistsValidatorDirective} from '../../../../../shared/components/exists-validator.directive';
import {CalendarModule} from 'primeng/calendar';
import {InputMaskModule} from 'primeng/inputmask';
import {RippleModule} from 'primeng/ripple';
import {ButtonModule} from 'primeng/button';
import {DatePipe, NgFor, NgIf} from '@angular/common';
import {SharedModule} from 'primeng/api';
import {TableModule} from 'primeng/table';
import {valueAccessorProvider} from '../../../../../shared/util/util';

import {stdConfirm} from '../../../../../shared/components/confirm';
import {TranslateModule} from '@ngx-translate/core';


@Component({
  selector: 'app-workweek-editor',
  template: `
    <div class="bg-white shadow-1 p-4" style="width: fit-content">
      <p-table [value]="workweeks" dataKey="id"
               editMode="row" tableStyleClass="w-auto"
               [editingRowKeys]="editingRowKeys">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let weekday of 7 | arrayOf; let i = index" class="day-cell"
                [style.filter]="i === 5 || i === 6 ? ' brightness(97%)' : 'none'">
              {{ weekdays[i] }}
            </th>
            <th class="text-center" style="width: 60px">{{'app.entities.workweek.total' | translate}}</th>
            <th *ngIf="usePeriod" class="text-center" style="width: 180px">{{'app.entities.workweek.startDate' | translate}}</th>
            <th *ngIf="usePeriod" class="text-center" style="width: 180px">{{'app.entities.workweek.endDate' | translate}}</th>
            <th *ngIf="usePeriod" class="text-center" style="width: 100px">{{'app.entities.workweek.active' | translate}}</th>
            <th class="text-center" style="width: 100px;">{{'app.entities.common.fields.name' | translate}}</th>
            <th class="flex align-items-center justify-content-center" style="width: 120px">
              <span class="mr-1">{{'shared.entityEditor.actions' | translate}}</span>
              <button pButton pRipple type="button" icon="pi pi-plus" [disabled]="!canAdd()"
                      (click)="addNew()"
                      class="p-button-rounded p-button-text p-button-danger"></button>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
          <tr [pEditableRow]="rowData">
            <td *ngFor="let weekday of 7 | arrayOf; let i = index" class="day-cell"
                [class.bg-gray-100]="i === 5 || i === 6">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputMask #model="ngModel" [(ngModel)]="rowData.hours[i]" mask="99:99" slotChar="0"
                               placeholder="00:00" (ngModelChange)="checkTimeStr(model, $event)"
                               styleClass="day-editor"></p-inputMask>
                </ng-template>
                <ng-template pTemplate="output">
                  <span (click)="canEdit() ? edit(rowData) : null">{{ rowData.hours[i] }}</span>
                </ng-template>
              </p-cellEditor>
            </td>
            <td class="text-center text-gray-600">
              {{ total(rowData) }}
            </td>
            <td *ngIf="usePeriod" class="text-center p-fluid">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <ng-container *ngIf="ri > 0">
                    <p-calendar #calModel="ngModel" [selectOtherMonths]="true"
                                [(ngModel)]="rowData.startDate" appendTo="body" [firstDayOfWeek]="1"
                                [readonlyInput]="true" [showIcon]="true" placeholder="Select a Monday"
                                [class.ng-dirty]="true"
                                [exists]="isExists(rowData)"
                                (ngModelChange)="onSelectDate(rowData, calModel)"
                                inputStyleClass="text-center"></p-calendar>
                    <app-control-error [control]="calModel.control"></app-control-error>
                  </ng-container>
                  <span *ngIf="ri === 0" class="text-gray-600">The beginning of time</span>
                </ng-template>
                <ng-template pTemplate="output">
                  <span *ngIf="ri === 0" class="text-gray-600">The beginning of time</span>
                  <span *ngIf="ri > 0" (click)="canEdit() ? edit(rowData) : null">
                      {{ rowData.startDate | date }}
                    </span>
                </ng-template>
              </p-cellEditor>
            </td>
            <td *ngIf="usePeriod" class="text-center">
              <span
                class="text-gray-600">{{ intervals[rowData.id].endDate ? (intervals[rowData.id].endDate | date) : 'The end of time' }}</span>
            </td>
            <td *ngIf="usePeriod" class="text-center">
              <i *ngIf="isActive(rowData)" class="pi pi-check-circle"></i>
            </td>
            <td style="max-width: 100px">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <ng-container *ngIf="ri > 0">
                    <input #model="ngModel" pInputText [(ngModel)]="rowData.name" nullable required
                           [class.ng-dirty]="true" style="width: 100%"
                           (ngModelChange)="checkNameExists(model, ri)">
                    <app-control-error [control]="model.control"></app-control-error>
                  </ng-container>
                  <div *ngIf="ri === 0" class="text-gray-600">{{ rowData.name }}</div>
                </ng-template>
                <ng-template pTemplate="output">
                  <div *ngIf="ri === 0" class="text-gray-600">{{ rowData.name }}</div>
                  <div *ngIf="ri > 0" (click)="canEdit() ? edit(rowData) : null"
                       class="mt-overflow-ellipsis" appTooltipOnOverflow>
                    {{ rowData.name }}
                  </div>
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              <div class="flex align-items-center justify-content-center relative">
                <i *ngIf="!isValid(rowData)"
                   class="text-xs pi pi-exclamation-triangle absolute right-0 top-0 p-error"></i>
                <ng-container *ngIf="!editing">
                  <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                          [disabled]="!canEdit()"
                          (click)="edit(rowData)" class="p-button-rounded p-button-text"></button>
                  <button pButton pRipple type="button" icon="pi pi-trash"
                          [disabled]="!canRemove(rowData, ri)"
                          (click)="remove($event, rowData, ri)" class="p-button-rounded p-button-text"></button>
                </ng-container>
                <ng-container *ngIf="editing">
                  <button pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                          [disabled]="!canSave(rowData)"
                          (click)="save(rowData, ri)"
                          class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                  <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                          (click)="cancelEdit(rowData, ri)"
                          class="p-button-rounded p-button-text p-button-danger"></button>
                </ng-container>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  styles: [
    `
      .day-cell {
        width: 70px;
        text-align: center !important;
      }

      :host ::ng-deep .p-inputtext.day-editor {
        width: 50px;
        text-align: center !important;
      }
    `
  ],
  standalone: true,
  providers: [valueAccessorProvider(WorkweekEditorComponent)],
  imports: [
    TableModule, SharedModule, NgFor, NgIf, ButtonModule, RippleModule, InputMaskModule, FormsModule, CalendarModule,
    ExistsValidatorDirective, ControlErrorComponent, InputTextModule, NullableDirective, TooltipOnOverflowDirective,
    DatePipe, ArrayOfPipe, TranslateModule
  ]
})
export class WorkweekEditorComponent extends AbstractControlValueAccessor<Array<IWorkweek>> {
  @Input() canRemoveDefault = true;
  @Input() usePeriod = true;
  workweeks: Array<IWorkweek> = [];
  weekdays: Array<string>;
  cache: Record<any, IWorkweek> = {};
  editingRowKeys: Record<any, boolean> = {};
  intervals: Record<any, { startDate: Date | null; endDate: Date | null }> = {};
  newId = 0;
  isEditedExists = false;

  constructor(public constants: ConstantsProviderService) {
    super();
    this.weekdays = constants.weekDays();
  }

  updateIntervals(): void {
    if (this.usePeriod) {
      const sorted = sortBy(this.workweeks, (w) => {
        const start = w.startDate == null ? new Date(0, 0, 0) : w.startDate;
        return start.getTime();
      });
      const intervals: Record<any, { startDate: Date | null; endDate: Date | null }> = {};
      sorted.forEach((ww, i) => {
        intervals[ww.id] = {
          startDate: ww.startDate!,
          endDate: i === sorted.length - 1 ? null :
            new Date(sorted[i + 1].startDate!.getFullYear(), sorted[i + 1].startDate!.getMonth(), sorted[i + 1].startDate!.getDate() - 1)
        }
      });
      this.intervals = intervals;
    }
  }

  defaultHours(): Array<string> {
    return range(0, 7).map((v) => v >= 0 && v < 5 ? '08:00' : '00:00');
  }

  total(workweek: IWorkweek): string {
    const minutes = sum(workweek.hours.map((v) => {
      return this.timeStrToMinutes(v);
    }));
    return this.minutesToTimeStr(minutes);
  }

  protected timeStrToMinutes(s: string): number {
    const [h, m] = s.split(':');
    return +h * 60 + (+m);
  }

  protected minutesToTimeStr(minutes: number): string {
    return `${padStart((~~(minutes / 60)).toString(), 2, '0')}:${padStart((minutes % 60).toString(), 2, '0')}`;
  }


  protected checkTimeStr(model: NgModel, s: string): void {
    const [h, m] = s.split(':');
    const minutes = +h * 60 + (+m);
    if (minutes > 24 * 60) {
      const s = '24:00';
      model.control.setValue(s);
    } else if (+m > 59) {
      const s = this.minutesToTimeStr(minutes);
      model.control.setValue(s);
    }
  }

  isExists(workweek: IWorkweek): (val?: any) => boolean {
    for (const ww of this.workweeks) {
      if (ww.id !== workweek.id && workweek.startDate != null && ww.startDate?.getTime() === workweek.startDate!.getTime()) {
        return () => true;
      }
    }
    return () => false;
  }

  onSelectDate(workweek: IWorkweek, model: NgModel): void {
    workweek.startDate = this.startOfThisWeek(workweek.startDate!);
    this.updateIntervals();
  }

  startOfThisWeek(date: Date): Date {
    return DateTime.fromJSDate(date).startOf('week').toJSDate();
  }

  startOfNextWeek(date: Date): Date {
    return DateTime.fromJSDate(date).endOf('week').plus({days: 1}).toJSDate();
  }

  isActive(workweek: IWorkweek): boolean {
    const current = new Date().getTime();
    const interval = this.intervals[workweek.id];
    return !!interval
      && (interval.startDate ?? new Date(0, 0, 0)).getTime() <= current
      && (interval.endDate ?? new Date(2100, 0, 0)).getTime() >= current;

  }

  canEdit(): boolean {
    return !this.isDisabled && isEmpty(this.editingRowKeys);
  }

  edit(workweek: IWorkweek): void {
    this.editingRowKeys[workweek.id] = true;
    this.cache[workweek.id] = cloneDeep(workweek);
    this.onValidatorChange();
  }

  cancelEdit(workweek: IWorkweek, index: number): void {
    this.workweeks[index] = this.cache[workweek.id];
    delete this.cache[workweek.id];
    delete this.editingRowKeys[workweek.id];
    this.isEditedExists = false;
    // if (this.isNewRecord(workweek)) {
    //   if (!this.canSave(workweek)) {
    //     remove(this.workweeks, {id: workweek.id});
    //   }
    // }
    this.updateIntervals();
    this.onValidatorChange();
  }

  canRemove(workweek: IWorkweek, rowIndex: number): boolean {
    return !this.isDisabled && (rowIndex > 0 || this.canRemoveDefault)
  }

  async remove(ev: any, workweek: IWorkweek, index: number) {
    const onRemove = () => {
      delete this.editingRowKeys[workweek.id];
      this.workweeks.splice(index, 1);
      delete this.cache[workweek.id];
      this.applyValue();
      this.updateIntervals();
    };
    if (!this.isNewRecord(workweek)) {
      if (await stdConfirm({
        target: ev.target,
        header: 'Delete Confirmation',
        message: `Are you sure you want to delete workweek "${workweek.name}"?`,
      }, 'app-confirm-popup')) {
        onRemove();
      }
    } else {
      onRemove();
    }
  }

  isValid(workweek: IWorkweek): boolean {
    return !!workweek.name && !this.isEditedExists && !this.isExists(workweek)();
  }

  canSave(workweek: IWorkweek): boolean {
    return !this.isDisabled && this.isValid(workweek) && !isEqual(workweek, this.cache[workweek.id]);
  }

  save(workweek: IWorkweek, index: number): void {
    delete this.cache[workweek.id];
    delete this.editingRowKeys[workweek.id];
    this.applyValue();
    this.updateIntervals();
  }

  applyValue(): void {
    this.value = this.workweeks.map((ww) => ({...ww, ...{id: this.isNewRecord(ww) ? null : ww.id} as IIdentified}));
    this.onModelChange(this.value);
    this.onModelTouched();
  }

  canAdd(): boolean {
    return !this.isDisabled && isEmpty(this.editingRowKeys);
  }

  checkNameExists(model: NgModel, idx: number): void {
    const found = this.workweeks.findIndex((w, i) => i !== idx && w.name === model.control.value) !== -1;
    model.control.setErrors(found ? {exists: true} : null);
    this.isEditedExists = found;
  }

  addNew(): void {
    const workweek: IWorkweek = {
      id: (++this.newId).toString(),
      hours: this.defaultHours(),
      startDate: !this.usePeriod || !this.workweeks.length ? null : this.startOfThisWeek(new Date()),
      name: !this.workweeks.length ? 'Default' : null as any,
      isArchived: false,
      isDefault: !this.workweeks.length,
    } as IWorkweek;
    this.workweeks.push(workweek);
    if (this.workweeks.length === 1) {
      this.applyValue();
    } else {
      this.editingRowKeys[workweek.id] = true;
      this.edit(workweek);
    }
    this.updateIntervals();
  }


  isNewRecord(workweek: IWorkweek): boolean {
    return !isNaN(workweek.id as any);
  }

  override writeValue(value: any) {
    this.cache = {};
    this.editingRowKeys = {};
    this.workweeks = value ? [...value] : [];
    super.writeValue(value);
    this.updateIntervals();
  }

  override validate(control: AbstractControl<any, any>): ValidationErrors | null {
    return some(this.workweeks, (w) => !this.isValid(w)) ? {
      invalidData: true
    } : null;
  }
}
