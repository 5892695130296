import { Component, Optional } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import {OrganizationAvatarComponent} from '../../../../shared/auth-provider/components/organization-avatar.component';

@Component({
  selector: 'app-main-menu-nav',
  template: `
    <div class="flex align-items-center">
      <app-organization-avatar/>
      <p-menubar [model]="mainMenuItems" [autoDisplay]="false"></p-menubar>
    </div>

  `,
  standalone: true,
  imports: [
    MenubarModule,
    OrganizationAvatarComponent
  ]
})
export class MainMenuNavComponent {
  mainMenuItems: MenuItem[];
  constructor() {
    this.mainMenuItems = []; /*MAIN_MENU.map((item) => {
      return {
        ...item
      };
    });*/
  }
}
