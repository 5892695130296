import { IAuthProviderConfig } from '../app/shared/auth-provider/auth-provider';

export interface IEnv {
  production: boolean;
  appName: string;
  apiUrl: string;
  authorizationUrl: string;
  authProviderConfig: IAuthProviderConfig;
  googleClientId?: string;
}

export const COMMON_ENV: Pick<IEnv, 'appName' | 'authorizationUrl'> = {
  appName: 'Conductor.ai',
  authorizationUrl: `/api/users/me`,
};
