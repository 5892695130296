import { Provider } from '@angular/core';
import { ILocaleConfig, LOCALE_CONFIG_TOKEN } from '../../shared/services/i18n/i18n.service';

import localeEs419 from '@angular/common/locales/es-419';

export function i18nConfig(): Provider {
  return {
    provide: LOCALE_CONFIG_TOKEN, useValue: {
      locales: {
        'es-419': { name: 'Española (América Latina)', data: localeEs419 },
      }
    } satisfies ILocaleConfig
  };
}
