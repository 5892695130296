import {Component} from '@angular/core';

@Component({
  selector: 'app-page-loader',
  template: `
    <div class="app-loading">
      <img src="assets/images/app-loading.svg" alt="Loading..." />
    </div>
  `,
  standalone: true
})
export class AppCommonPageLoaderComponent {
}
