import {Component} from "@angular/core";
import {
  ColumnHeaderComponent,
  ColumnHeaderSelectorComponent
} from '../../../../../shared/components/table/column-header.component';
import {HolidayCalendarsResourceService} from '../../../../resources/dictionaries/holiday-calendars-resource.service';
import {AsyncPipe} from "@angular/common";
import {
  TableCellComponent,
  TableCellSelectorComponent
} from "../../../../../shared/components/table/table-cell.component";
import {
  CommonColumnHeaderIsArchived,
  CommonTableCellIsArchived,
  CommonTableCellName
} from "../../../common/common-controls-wrappers";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {SpinnerizerDirective} from "../../../../../shared/components/spinnerizer.component";
import {TableToolbarComponent} from "../../../../../shared/components/table/table-menus";
import {EntityTableComponent} from '../../../../../shared/components/table/entity-table.component';
import {FilterByQueryParamsDirective} from '../../../../../shared/components/table/filter-by-query-params.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-holiday-calendars-table',
  template: `
    <app-entity-table #entityTable [filterByQueryParams]="[{field: 'countryCode', matchMode: 'in', isValueArray: true}]"
                      [api]="holidayCalendarsResource" [defaultSort]="[{field: 'name'}]"
                      [omitMenuItems]="['edit']" [showActions]="false"
                      editUrl="/settings/holiday-calendars/edit" [ignoreEditUrlFor]="null" stateKey="holidayCalendars.table">
      <ng-template pTemplate="$header">
        <app-th field="name" [label]="'app.entities.common.fields.name' | translate" 
                filterType="text" [fixedWidth]="350" frozen="lastLeft"></app-th>
        <app-th field="countryCode" [label]="'app.entities.common.fields.countryCode' | translate"
                filterType="optionsIn" label="Origin Country"
                optionValue="code" optionLabel="name"
                optionIcon="flag" onDemandOptions="countries">
        </app-th>
        <app-th field="holidayCount" [label]="'app.entities.holidayCalendar.fields.holidayCount' | translate"
                filterType="numeric" label="Holidays" [fixedWidth]="100"></app-th>
        <app-th field="description" [label]="'app.entities.common.fields.description' | translate"
                filterType="text" [fixedWidth]="350"></app-th>
        <app-th field="baseName" filterType="text" label="Based On"
                [fixedWidth]="350"></app-th>
        <app-th-isArchived/>
      </ng-template>
      <ng-template pTemplate="$body" let-rowData>
        <app-td-name [rowData]="rowData" [routerLink]="true"/>
        <app-td type="custom" [rowData]="rowData" field="countryCode" tdStyleClass="py-0">
          <img class="mr-2" [src]="rowData.countryFlag" width="24">
          <span>{{ rowData.countryCode }}</span>
        </app-td>
        <app-td field="holidayCount" [rowData]="rowData" align="right"></app-td>
        <app-td field="description" [rowData]="rowData"></app-td>
        <app-td field="baseName" [rowData]="rowData"
                nullSymbol="\u{0276A} origin \u{0276B}"></app-td>
        <app-td-isArchived [rowData]="rowData"/>
      </ng-template>
    </app-entity-table>
  `,
  standalone: true,
  imports: [
    TableToolbarComponent, TableModule, SharedModule, ColumnHeaderSelectorComponent,
    ColumnHeaderComponent, CommonColumnHeaderIsArchived, TableCellSelectorComponent, CommonTableCellName,
    TableCellComponent, CommonTableCellIsArchived, AsyncPipe, SpinnerizerDirective, EntityTableComponent,
    FilterByQueryParamsDirective, TranslateModule
  ]
})
export class HolidayCalendarsTableComponent {
  constructor(public holidayCalendarsResource: HolidayCalendarsResourceService) {
  }
}
