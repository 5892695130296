import { Inject, Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { UserProfileService } from './user-profile.service';
import { COMMON_LIB_CONFIG_TOKEN, ICommonLibConfig } from '../common-lib-config';

@Injectable({
  providedIn: 'root'
})
export class PageTitleStrategy extends TitleStrategy {
  constructor(private title: Title, private userProfileService: UserProfileService,
              @Inject(COMMON_LIB_CONFIG_TOKEN) private commonConfig: ICommonLibConfig) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    this.userProfileService.userProfile$.pipe(
    ).subscribe(userInfo => {
      this.setTitle(routerState, userInfo?.organization.name);
    });
  }

  private setTitle(routerState: RouterStateSnapshot, orgName: string | undefined): void {
    const routeTitle = this.buildTitle(routerState);
    let title = this.commonConfig.appName;
    if (orgName) {
      title += ` : ${ orgName }`;
    }
    if (routeTitle) {
      title += ` - ${ routeTitle }`;
    }
    this.title.setTitle(title);
  }
}
