import {Component, ViewChild} from '@angular/core';
import {SkillsResourceService} from '../../../../resources/dictionaries/skills-resource.service';
import {IRowMenuItem, ITableMenuItem, MENU_SEPARATOR} from '../../../../../shared/components/table/table-menus';
import {ISkill} from '../../../../../api/shared/app-domain/dictionaries';
import {OptionsPipe} from '../../../../../shared/components/options.pipe';
import {AsyncPipe} from '@angular/common';
import {TableCellComponent} from '../../../../../shared/components/table/table-cell.component';
import {
  CommonColumnHeaderIsArchived,
  CommonTableCellIsArchived,
  CommonTableCellName
} from '../../../common/common-controls-wrappers';
import {ColumnHeaderComponent} from '../../../../../shared/components/table/column-header.component';
import {EntityTableComponent} from '../../../../../shared/components/table/entity-table.component';
import {SkillEditorComponent} from './skill-editor.component';
import {SharedModule} from 'primeng/api';
import {EntityEditDialogComponent} from '../../../../../shared/components/entity-editor/entity-edit.dialog';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-skills-table',
  template: `
    <app-entity-edit-dialog #editDialog width="80vw" minWidth="750px">
      <ng-template pTemplate let-param>
        <app-skill-editor [param]="param"></app-skill-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-entity-table #entityTable [api]="skillsResource" [defaultSort]="[{field: 'name'}]"
                      editUrl="/settings/skills/edit"
                      [omitMenuItems]="['edit']" [customMenuItems]="customTableMenuItems"
                      [editDialog]="editDialog" [ignoreEditUrlFor]="$any(['newEntity', 'editMenu'])"
                      stateKey="skills.table">
      <ng-template pTemplate="$header">
        <app-th field="name" [label]="'app.entities.common.fields.name' | translate"
                filterType="text" [fixedWidth]="300" frozen="lastLeft"></app-th>
        <app-th field="isOkToPublish" [label]="'app.entities.skill.fields.isOkToPublish' | translate"
                filterType="boolean" [fixedWidth]="180"></app-th>
        <app-th field="isForReview" [label]="'app.entities.skill.fields.isForReview' | translate"
                filterType="boolean" [fixedWidth]="180"></app-th>
        <app-th field="silo" [label]="'app.entities.skill.fields.silo' | translate"
                filterType="optionsIn" [fixedWidth]="200" onDemandOptions="skillSilos"></app-th>
        <app-th field="adjective" [label]="'app.entities.skill.fields.adjective' | translate"
                filterType="text" [fixedWidth]="200"></app-th>
        <app-th field="singular" [label]="'app.entities.skill.fields.singular' | translate"
                filterType="text" [fixedWidth]="200"></app-th>
        <app-th field="plural" [label]="'app.entities.skill.fields.plural' | translate"
                filterType="text" [fixedWidth]="200"></app-th>
        <app-th field="team" [label]="'app.entities.skill.fields.team' | translate"
                filterType="text" [fixedWidth]="200"></app-th>
        <app-th field="teams" [label]="'app.entities.skill.fields.teams' | translate"
                filterType="text" [fixedWidth]="200"></app-th>
        <app-th field="siloMember" [label]="'app.entities.skill.fields.siloMember' | translate"
                filterType="text" [fixedWidth]="200"></app-th>
        <app-th field="siloMembers" [label]="'app.entities.skill.fields.siloMembers' | translate"
                filterType="text" [fixedWidth]="200"></app-th>
        <app-th field="urlSlug" [label]="'app.entities.skill.fields.urlSlug' | translate"
                filterType="text" [fixedWidth]="200"></app-th>
        <app-th field="metaTitle" [label]="'app.entities.skill.fields.metaTitle' | translate"
                filterType="text" [fixedWidth]="200"></app-th>
        <app-th field="metaDescriptions" [label]="'app.entities.skill.fields.metaDescriptions' | translate"
                filterType="text" [fixedWidth]="200"></app-th>
        <app-th-isArchived/>
      </ng-template>
      <ng-template pTemplate="$body" let-rowData>
        <app-td-name [rowData]="rowData"
                     [customMenuItems]="customRowMenuItems" [omitMenuItems]="['duplicate']"
                     [routerLink]="true"
                     (onClick)="entityTable.openCreateOrUpdate(rowData)"/>
        <app-td field="isOkToPublish" [rowData]="rowData" type="boolean"></app-td>
        <app-td field="isForReview" [rowData]="rowData" type="boolean"></app-td>
        <app-td field="silo" [rowData]="rowData"></app-td>
        <app-td field="adjective" [rowData]="rowData"></app-td>
        <app-td field="singular" [rowData]="rowData"></app-td>
        <app-td field="plural" [rowData]="rowData"></app-td>
        <app-td field="team" [rowData]="rowData"></app-td>
        <app-td field="teams" [rowData]="rowData"></app-td>
        <app-td field="siloMember" [rowData]="rowData"></app-td>
        <app-td field="siloMembers" [rowData]="rowData"></app-td>
        <app-td field="urlSlug" [rowData]="rowData"></app-td>
        <app-td field="metaTitle" [rowData]="rowData"></app-td>
        <app-td field="metaDescriptions" [rowData]="rowData"></app-td>
        <app-td-isArchived [rowData]="rowData"/>
      </ng-template>
    </app-entity-table>
  `,
  standalone: true,
  imports: [
    EntityEditDialogComponent,
    SharedModule,
    SkillEditorComponent,
    EntityTableComponent,
    ColumnHeaderComponent,
    CommonColumnHeaderIsArchived,
    CommonTableCellName,
    TableCellComponent,
    CommonTableCellIsArchived,
    AsyncPipe,
    OptionsPipe,
    TranslateModule,
  ],
})
export class SkillsTableComponent {
  customTableMenuItems: Array<ITableMenuItem<ISkill>> = [
    {
      id: 'publish',
      i18: 'app.entities.skill.tableActions.publish',
      icon: 'pi pi-reply',
      isEnabled: (param) => !param.data.isOkToPublish,
      onCommand: (param) => param.entityTable.patchEntities(param.data, {isOkToPublish: true})
    },
    {
      id: 'review',
      i18: 'app.entities.skill.tableActions.review',
      icon: 'pi pi-eye',
      isEnabled: (param) => param.data.isForReview,
      onCommand: (param) => param.entityTable.patchEntities(param.data, {isForReview: false})
    },
    MENU_SEPARATOR
  ];

  customRowMenuItems: Array<IRowMenuItem<ISkill>> = [
    {
      id: 'publish',
      i18: 'app.entities.skill.tableActions.publish',
      icon: 'pi pi-reply',
      isEnabled: (param) => !param.data.isOkToPublish,
      onCommand: (param) => param.entityTable.patchEntities([param.data], {isOkToPublish: true})
    },
    {
      id: 'review',
      i18: 'app.entities.skill.tableActions.review',
      icon: 'pi pi-eye',
      isEnabled: (param) => param.data.isForReview,
      onCommand: (param) => param.entityTable.patchEntities([param.data], {isForReview: false})
    },
    MENU_SEPARATOR
  ]

  constructor(public skillsResource: SkillsResourceService) {
  }

}
