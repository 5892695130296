import {Component} from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SkeletonModule} from 'primeng/skeleton';
import {BadgeModule} from 'primeng/badge';
import {UserNavComponent} from './user-nav.component';
import {SettingsNav} from './settings-nav.component';
import {MainMenuNavComponent} from './main-menu-nav.component';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-nav-bar',
  template: `
<!--    <div class="mt-app-nav-bar">-->
      <app-main-menu-nav></app-main-menu-nav>
      <div class="flex align-items-center justify-content-end h-full">
        <ng-container>
          <button pButton pRipple type="button" [label]="'app.trackTime.trackTime' | translate"
                  icon="pi pi-clock"
                  class="p-button-rounded p-button-text text-white text-sm mr-4"
                  (click)="opTrackTime.toggle($event)">
          </button>
          <p-overlayPanel #opTrackTime>
            <ng-template pTemplate>
              <h5 translate="app.trackTime.timeEntry"></h5>
              <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
              <p-skeleton width="30rem" styleClass="mb-2"></p-skeleton>
              <p-skeleton width="15rem" height="4rem" styleClass="mb-2"></p-skeleton>
            </ng-template>
          </p-overlayPanel>
        </ng-container>
        <app-settings-nav></app-settings-nav>
        <button pButton pRipple type="button" icon="pi pi-bell"
                class="p-button-rounded p-button-text text-white relative">
          <p-badge [value]="'5'" severity="success" class="absolute" [style.top]="'.2rem'" [style.right]="'.4rem'">
          </p-badge>
        </button>
        <div class="ml-3 mr-3 mt-app-nav-bar-divider"></div>
        <app-user-nav></app-user-nav>
      </div>
<!--    </div>-->
  `,
  host: {
    class: 'mt-app-nav-bar'
  },
  standalone: true,
  imports: [
    MainMenuNavComponent,
    OverlayPanelModule,
    SkeletonModule,
    ButtonModule,
    RippleModule,
    TranslateModule,
    SettingsNav,
    BadgeModule,
    UserNavComponent
  ]
})
export class NavBarComponent {
}
