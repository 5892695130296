import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthProviderService, IAuthUser } from '../../../shared/auth-provider/auth-provider';
import { AuthService } from '@auth0/auth0-angular';
import { lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class Auth0AuthProviderService extends AuthProviderService {
  constructor(public readonly auth0Service: AuthService) {
    super();
    this._isAuthenticated$ = auth0Service.isAuthenticated$;
    this._user$ = auth0Service.user$.pipe(
      map((auth0User) => {
        let user: IAuthUser | null = null;
        if (auth0User) {
          user = {
            familyName: auth0User.family_name!,
            givenName: auth0User.given_name,
            email: auth0User.email,
            avatar: auth0User.picture || null
          };
        }
        return user;
      }));
    this._isLoading$ = auth0Service.isLoading$;
  }

  override login(): Promise<void> {
    return lastValueFrom(this.auth0Service.loginWithRedirect());
  }

  override logout(): Promise<void> {
    return lastValueFrom(this.auth0Service.logout({
      logoutParams: {
        returnTo: document.location.origin,
      },
    }));
  }

  override signUp(): Promise<void> {
    return lastValueFrom(this.auth0Service.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup'
      }
    }));
  }

  override getNativeService(): AuthService {
    return this.auth0Service;
  }
}


