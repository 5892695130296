import {Component, inject, ViewChild} from '@angular/core';
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel';
import {MenuModule} from 'primeng/menu';
import {ListboxModule} from 'primeng/listbox';
import {NgIf} from '@angular/common';
import {MenuItem, SharedModule} from 'primeng/api';
import {USER_MENU} from '../main-menu-models';
import {find} from 'lodash';
import {MenuItemCommandEvent} from 'primeng/api/menuitem';
import {TranslateModule} from '@ngx-translate/core';
import {TranslateHelperService} from '../../../../shared/services/i18n/i18n.service';
import {UserAvatarComponent} from '../../../../shared/auth-provider/components/user-avatar.component';
import {SwitchOrganizationPanelComponent} from '../../../../shared/auth-provider/components/switch-organization-panel.component';
import {HiUserComponent} from '../../../../shared/auth-provider/components/hi-user.component';
import {AuthProviderService} from '../../../../shared/auth-provider/auth-provider';
import {removeDeniedMenuItems} from '../../../../shared/auth-provider/permissions';

@Component({
  selector: 'app-user-nav',
  template: `
    <div class="flex align-items-center cursor-pointer h-full" (click)="opUser.toggle($event)">
      <app-user-avatar/>
      <i class="ml-1 pi pi-angle-down"></i>
    </div>
    <p-overlayPanel #opUser styleClass="mt-user-popup" (onShow)="onShowUserMenu()">
      <ng-template pTemplate>
        <div class="border-bottom-1 border-gray-300 px-3 py-2">
          <app-hi-user/>
        </div>
        <p-menu [model]="userMenuItems"></p-menu>
      </ng-template>
    </p-overlayPanel>
    <app-switch-organization-panel #opOrgs/>
  `,
  imports: [
    OverlayPanelModule,
    MenuModule,
    ListboxModule,
    NgIf,
    SharedModule,
    TranslateModule,
    SwitchOrganizationPanelComponent,
    UserAvatarComponent,
    HiUserComponent,
    UserAvatarComponent,
    HiUserComponent
  ],
  standalone: true,
  styles: [
    `
      ::ng-deep .mt-user-popup.p-overlaypanel .p-overlaypanel-content {
        padding: 0;
      }

      ::ng-deep .mt-user-popup.p-overlaypanel .p-overlaypanel-content .p-menu {
        border: none;
        font-size: .8rem;
      }
    `
  ]
})
export class UserNavComponent {
  protected authProvider = inject(AuthProviderService);
  userMenuItems: MenuItem[];
  @ViewChild('opUser') userMenuPanel!: OverlayPanel;
  @ViewChild('opOrgs') orgsPanel!: SwitchOrganizationPanelComponent;

  constructor(protected translateHelper: TranslateHelperService) {
    this.userMenuItems = removeDeniedMenuItems(USER_MENU).map((item) => {
      return {
        ...item,
        ...{
          command: () => {
            switch (item.id) {
              case 'sign-out':
                this.authProvider.logout();
                break;
              default:
                this.userMenuPanel.hide();
            }
          }
        }
      };
    });
  }

  onShowUserMenu(): void {
    find(this.userMenuItems, {id: 'switch-workspace-sep'})!.visible = this.orgsPanel.canSwitchOrg();
    const item = find(this.userMenuItems, {id: 'switch-workspace'})!;
    item.visible = this.orgsPanel.canSwitchOrg();
    item.command = (event: MenuItemCommandEvent) => this.orgsPanel.show(event.originalEvent!);
    this.translateHelper.translateMenu(this.userMenuItems);
  }

}
