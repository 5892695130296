import {Component, OnInit, ViewChild} from '@angular/core';
import {finalize} from 'rxjs';
import {FormsModule, NgModel} from '@angular/forms';
import {IWorkweek} from '../../../../../api/shared/app-domain/dictionaries';
import {WorkweeksResourceService} from '../../../../resources/dictionaries/workweeks-resource.service';
import {cloneDeep, omit} from 'lodash';
import {WORKWEEK_EXCLUDED_PROPS} from '../../../../resources/types';
import {Alert} from '../../../../../shared/util/alert';
import {WorkweekEditorComponent} from './workweek-editor.component';
import {SpinnerizerDirective} from '../../../../../shared/components/spinnerizer.component';
import {RippleModule} from 'primeng/ripple';
import {ButtonModule} from 'primeng/button';
import {SharedModule} from 'primeng/api';
import {CommonPageComponent} from '../../../../../shared/components/page/common-page.component';
import {IDataModifier} from '../../../../../shared/components/common';
import {deepDifference} from '../../../../../shared/util/util';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-workweek-page',
  template: `
    <app-common-page entityName="app.entities.workweek.name" onDemandCountMethod="workweeksCount" [showNewEntityButton]="false">
      <ng-template pTemplate="topHeader.right">
        <button pButton pRipple [label]="'shared.actions.apply' | translate"
                class="ml-5" [disabled]="!model || model.invalid || !model.touched"
                icon="pi pi-check" (click)="apply(model)"></button>
      </ng-template>
      <ng-template pTemplate="content">
        <div [spinnerizer]="loading" class="mt-4 flex justify-content-center">
          <app-workweek-editor #model="ngModel" [(ngModel)]="workweeks" [canRemoveDefault]="false"
                               [usePeriod]="false"></app-workweek-editor>
        </div>
      </ng-template>
    </app-common-page>
  `,
  standalone: true,
  imports: [
    CommonPageComponent, SharedModule, ButtonModule, RippleModule, WorkweekEditorComponent,
    FormsModule, SpinnerizerDirective, TranslateModule
  ]
})
export class WorkweekPageComponent implements OnInit, IDataModifier {
  @ViewChild('model') model!: NgModel;
  loading = false;

  workweeks: Array<IWorkweek> = [];
  workweeksSave: Array<IWorkweek> = [];

  constructor(public workweeksResource: WorkweeksResourceService, protected translate: TranslateService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.workweeksResource.searchEntities({})
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((response) => {
        this.workweeks = response.results;
        this.workweeksSave = cloneDeep(this.workweeks);
      });
  }

  apply(model: NgModel): void {
    this.loading = true;
    this.workweeksResource.replaceWorkweeks(this.workweeks.map((w) => omit(w, WORKWEEK_EXCLUDED_PROPS)))
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((data) => {
        this.workweeks = data;
        this.workweeksSave = cloneDeep(this.workweeks);
        model.control.markAsUntouched();
        Alert.message({
          severity: 'success',
          summary: this.translate.instant('shared.messages.success'),
          detail: this.translate.instant('app.entities.workweek.updatedMessage')
        });
      });
  }

  hasUnsavedChanges(): boolean {
    return deepDifference(this.workweeks, this.workweeksSave, true);
  }

}
