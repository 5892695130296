import {
  AfterContentInit,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  inject,
  Input,
  QueryList,
  TemplateRef
} from '@angular/core';
import {AbstractEntityEditor, IEntityEditorParam} from './abstract-entity-editor';
import { ActivatedRoute, Router } from '@angular/router';
import {AbstractEntityEditorBase} from './abstract-entity-editor-base';
import {MenuItem, PrimeTemplate} from 'primeng/api';
import {find} from 'lodash';
import {IIdentified} from '../../../api/shared/common';
import {ButtonModule} from 'primeng/button';
import {MenuModule} from 'primeng/menu';
import {NgIf, NgTemplateOutlet} from '@angular/common';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { getUrlWithoutLastSegmentParams, parseUrl, routeParamsToObject } from '../../util/util';
import {Alert} from '../../util/alert';
import {IEntityResource} from '../../services/resources/entity-resource';
import {RouterNavigate} from '../../services/router-navigate.service';
import {stdConfirm} from '../confirm';
import {TranslateModule} from '@ngx-translate/core';
import {TranslateHelperService} from '../../services/i18n/i18n.service';
import {ILocalized} from '../../types';

@Component({
  selector: 'app-entity-edit-page',
  template: `
    <div class="mt-page-header">
      <div class="mt-page-header-content">
        <div class="grid grid-nogutter align-items-center">
          <div class="col-6">
            @if (initialized) {
              @if (tplTitle) {
                <ng-container *ngTemplateOutlet="tplTitle; context:{$implicit: param, title, entity}"></ng-container>
              } @else {
                <div class="mt-page-title">
                  {{ title }}
                  <i *ngIf="hasBeenDeleted" class="pi pi-trash ml-2 text-2xl"></i>
                </div>
              }
              @if (tplSubTitle && !!entity && param.id != null) {
                <ng-container *ngTemplateOutlet="tplSubTitle; context:{$implicit: param, entity}"></ng-container>
              }
            }
          </div>
          @if (initialized && entity && !hasBeenDeleted) {
            <div class="col-6 flex justify-content-end align-items-center">
              <p-menu #actionsMenu [popup]="true" [model]="actionItems" appendTo="body" (onShow)="translateHelper.translateMenu(actionItems)"></p-menu>
              @if (!canSubmit() && editor!.applyAttempted && !editor!.isValid()) {
                <p-message severity="warning" [text]="'shared.entityEditor.invalidDataInForm' | translate" class="mr-3"></p-message>
              }
              <p-button *ngIf="this.param.id != null" [label]="'shared.entityEditor.actions' | translate"
                        class="p-button-outlined mr-2"
                        icon="pi pi-chevron-down"
                        iconPos="right" (onClick)="processActionMenu(); actionsMenu.toggle($event)"></p-button>
              <p-button icon="pi pi-check"
                        [disabled]="!canSubmit()"
                        (onClick)="performApply()"
                        [label]="(isEditExisting() || param.id == null ? 'shared.actions.apply' : 'shared.actions.duplicate') | translate"></p-button>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="mt-4">
      <p-messages *ngIf="hasBeenDeleted" key="tcDeleted"
                  [value]="[{severity:'info', summary: 'shared.messages.success' | translate, detail: 'shared.messages.deletedNamed' | translate: {name: entryName} }]"></p-messages>
      <div [style.visibility]="initialized && !!entity && !hasBeenDeleted ? 'visible' : 'hidden'">
        <ng-container *ngTemplateOutlet="tplEditor; context:{$implicit: param}"></ng-container>
      </div>
    </div>
  `,
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, MenuModule, ButtonModule, MessagesModule, MessageModule, TranslateModule]
})
export class EntityEditPageComponent implements AfterContentInit, AfterViewChecked {
  @Input({required: true}) api: Partial<IEntityResource<any, any>> = {};
  @Input() pageUrl?: string;
  @ContentChild(AbstractEntityEditorBase) editor?: AbstractEntityEditor<any, any>;
  @ContentChildren(PrimeTemplate) protected templates!: QueryList<PrimeTemplate>;
  protected param!: IEntityEditorParam;
  protected tplEditor!: TemplateRef<any>;
  protected tplTitle!: TemplateRef<any>;
  protected tplSubTitle!: TemplateRef<any>;
  protected hasBeenDeleted = false;
  protected initialized = false;
  private router = inject(Router);
  private cdr = inject(ChangeDetectorRef);
  private routerNavigate = inject(RouterNavigate);
  private activatedRoute = inject(ActivatedRoute);
  private skipRoutes = true;

  actionItems: Array<MenuItem & ILocalized> = [
    {
      id: 'edit',
      i18n: 'shared.actions.edit',
      icon: 'pi pi-pencil',
      command: () =>
        this.router.navigate([this.getPageUrl(), {id: this.param.id}], {replaceUrl: this.skipRoutes})
    },
    {
      id: 'duplicate',
      i18n: 'shared.actions.duplicate',
      icon: 'pi pi-clone',
      command: () =>
        this.router.navigate([this.getPageUrl(), {id: this.param.id, duplicate: true}],
          {skipLocationChange: this.skipRoutes})
    },
    {
      id: 'archive',
      i18n: 'shared.actions.archive',
      icon: 'pi pi-server',
      command: () => this.performArchive()
    },
    {
      id: 'delete',
      i18n: 'shared.actions.delete',
      icon: 'pi pi-trash',
      styleClass: 'mt-alert-menuitem',
      command: () => this.performDelete()
    }
  ];


  constructor(protected translateHelper: TranslateHelperService) {

    routeParamsToObject<IEntityEditorParam>({
      id: {},
      duplicate: {type: 'boolean'}
    })
      .subscribe((param) => {
        this.param = {
          id: param.id || null,
          duplicate: param.duplicate
        };
        this.hasBeenDeleted = false;
      });
  }

  ngAfterContentInit(): void {
    this.templates.forEach((tpl) => {
      if (tpl.getType() === 'editor') {
        this.tplEditor = tpl.template;
      } else if (tpl.getType() === 'title') {
        this.tplTitle = tpl.template;
      } else if (tpl.getType() === 'subTitle') {
        this.tplSubTitle = tpl.template;
      }
    });
  }

  ngAfterViewChecked(): void {
    if (!this.initialized && this.editor) {
      this.initialized = true;
      this.cdr.detectChanges();
    }
  }

  isDataChanged(): boolean {
    return !!this.editor?.isDataChanged();
  }

  canSubmit(): boolean {
    if (!this.editor) {
      return false;
    }
    return this.editor.canSubmit();
  }

  protected processActionMenu(): void {
    find(this.actionItems, {id: 'edit'})!.disabled = !this.param.duplicate;
    find(this.actionItems, {id: 'archive'})!.disabled = !this.canArchive();
    find(this.actionItems, {id: 'duplicate'})!.disabled = this.param.duplicate;
    find(this.actionItems, {id: 'delete'})!.disabled = !this.isEditExisting();
  }

  isEditExisting(): boolean {
    return !!this.param.id && !this.param.duplicate;
  }

  getPageUrl(): string {
    return this.pageUrl || getUrlWithoutLastSegmentParams(this.activatedRoute.snapshot.url);
  }

  get entryName(): string {
    return this.editor?.entryName() ?? 'Entry';
  }

  get title(): string {
    return this.editor?.title || '';
  }

  get entity(): any {
    return this.editor?.entity;
  }

  protected performApply(): void {
    if (this.editor?.canPerformApply()) {
      this.editor.apply((result: IIdentified) => {
        if (!this.isEditExisting()) {
          this.router.navigate([this.getPageUrl(), {id: result.id}], {replaceUrl: true});
        }
      });
    }
  }

  async performDelete() {
    if (!this.editor || !this.api.deleteEntities || !this.entity || !this.isEditExisting()) {
      return;
    }
    if (await stdConfirm({
      header: this.translateHelper.translate.instant('shared.messages.deleteConfirmation'),
      message: this.translateHelper.translate.instant('shared.messages.askDeleteNamed', {name: this.entryName}),
    })) {
      this.api.deleteEntities!([(this.entity as any).id])
        .subscribe(() => {
          this.hasBeenDeleted = true;
          this.editor!.reset();
          Alert.message({
            severity: 'success',
            summary: this.translateHelper.translate.instant('shared.messages.success'),
            detail: this.translateHelper.translate.instant('shared.messages.deletedNamed', {name: this.entryName})
          });
          if (this.skipRoutes) {
            this.routerNavigate.back();
          }
        });
    }
  }

  canArchive(): boolean {
    return !!this.editor && !!this.api.archiveEntities &&
      !!this.entity && this.isEditExisting() && !(this.entity as any)?.isArchived;
  }

  performArchive(): void {
    if (!this.canArchive()) {
      return;
    }
    const data = [{id: this.param.id}];
    this.api.archiveEntities!(data as Array<IIdentified>)
      .subscribe(() => {
        (this.entity as any).isArchived = true;
        this.editor!.assignValue('isArchived' as any, (this.entity as any).isArchived);
        Alert.message({
          severity: 'success',
          summary: this.translateHelper.translate.instant('shared.messages.success'),
          detail: this.translateHelper.translate.instant('shared.messages.archivedNamed', {name: this.entryName})
        });
      });
  }

}
