import {Component} from '@angular/core';
import {EntityEditDialogComponent} from '../../../../shared/components/entity-editor/entity-edit.dialog';
import {SharedModule} from 'primeng/api';
import {EntityTableComponent} from '../../../../shared/components/table/entity-table.component';
import {ColumnHeaderComponent} from '../../../../shared/components/table/column-header.component';
import {
  CommonColumnHeaderIsArchived, CommonColumnHeaderName,
  CommonTableCellIsArchived,
  CommonTableCellName
} from '../../common/common-controls-wrappers';
import {TranslateModule} from '@ngx-translate/core';
import {OrganizationsResourceService} from '../../../resources/organizations-resource.service';
import { TableCellComponent } from '../../../../shared/components/table/table-cell.component';
import { OrganizationEditorComponent } from './organization-editor.component';

@Component({
  selector: 'app-organizations-table',
  template: `
    <app-entity-edit-dialog #editDialog width="500px">
      <ng-template pTemplate let-param>
        <app-organization-editor [param]="param"></app-organization-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-entity-table #entityTable
                      [api]="organizationsResource" [defaultSort]="[{field: 'name'}]"
                      [pageable]="false" [omitMenuItems]="['edit']" [showActions]="false"
                      [editDialog]="editDialog" stateKey="organizations.table">
      <ng-template pTemplate="$header">
        <app-th-name/>
        <app-th field="displayName" [label]="'app.entities.organization.fields.displayName' | translate" filterType="text" ></app-th>
        <app-th field="email" [label]="'app.entities.organization.fields.ownerEmail' | translate" filterType="text" ></app-th>
        <app-th field="countryCode" [label]="'app.entities.organization.fields.dataRegion' | translate"
                filterType="optionsIn" label="Origin Country"
                optionValue="code" optionLabel="name"
                optionIcon="flag" onDemandOptions="countries">
        </app-th>
        <app-th field="userCount" [label]="'app.entities.organization.fields.userCount' | translate" filterType="text" ></app-th>
        <app-th field="useMFA" [label]="'app.entities.organization.fields.useMFA' | translate" filterType="boolean" ></app-th>
        <app-th-isArchived/>
      </ng-template>
      <ng-template pTemplate="$body" let-rowData>
        <app-td-name [rowData]="rowData" [omitMenuItems]="['duplicate']" [showAvatar]="true"
                     (onClick)="entityTable.openCreateOrUpdate(rowData)"/>
        <app-td field="displayName" [rowData]="rowData"></app-td>
        <app-td field="email" type="custom" [rowData]="rowData">
          <a [href]="'mailto:' + rowData.email" target="_top">{{rowData.email}}</a>
        </app-td>
        <app-td type="custom" [rowData]="rowData" field="countryCode" tdStyleClass="py-0">
          <img class="mr-2" [src]="rowData.countryFlag" width="24">
          <span>{{ rowData.countryCode }}</span>
        </app-td>
        <app-td field="userCount" [rowData]="rowData"></app-td>
        <app-td field="useMFA" [rowData]="rowData" type="boolean"></app-td>
        <app-td-isArchived [rowData]="rowData"/>
      </ng-template>
    </app-entity-table>
  `,
  standalone: true,
  imports: [
    EntityEditDialogComponent, SharedModule, EntityTableComponent, ColumnHeaderComponent,
    CommonColumnHeaderIsArchived, CommonTableCellName, CommonTableCellIsArchived, TranslateModule, CommonColumnHeaderName, TableCellComponent, OrganizationEditorComponent
  ]
})
export class OrganizationsTableComponent {
  constructor(protected organizationsResource: OrganizationsResourceService) {
  }
}
