import { Provider } from '@angular/core';
import { COMMON_LIB_CONFIG_TOKEN, ICommonLibConfig } from '../../shared/common-lib-config';
import { environment } from '../../../environments/environment';

export function commonLibConfig(): Provider {
  return {
    provide: COMMON_LIB_CONFIG_TOKEN,
    useValue: {
      appName: environment.appName,
      apiUrl: environment.apiUrl,
      authorizationUrl: environment.authorizationUrl,
      imageServiceUrl: '/api/images/upload',
      fileStorageUrl: '/api/file-storage/upload',
      authCustomConfig: {
        googleAuthUrl: '/api/auth/google',
        passwordAuthUrl: '/api/auth/password',
        refreshTokenUrl: '/api/auth/refresh-token',
        userProfileApiUrl: '/api/users/me',
      },
      authProviderConfig: {...environment.authProviderConfig}
    } satisfies ICommonLibConfig
  };
}
