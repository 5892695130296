import { CanDeactivateFn } from '@angular/router';
import { IDataModifier } from '../components/common';
import { confirmExitUnsaved } from '../components/confirm';

export const pendingChangesGuard: CanDeactivateFn<IDataModifier> = (component: IDataModifier) => {
  if (component.hasUnsavedChanges()) {
    return confirmExitUnsaved();
  }
  return true;
}
