import {Component} from '@angular/core';
import { CommonPageComponent } from '../../shared/components/page/common-page.component';
import { DepartmentsTableComponent } from './app-domain/dictionaries/departments-page.component';
import { SharedModule } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import { ArrayOfPipe } from '../../shared/components/array-of.pipe';
import { AsArrayPipe } from '../../shared/components/as-array.pipe';

@Component({
  selector: 'app-landing-page',
  template: `
    <app-common-page entityName="app.entities.landingPage.name" [showNewEntityButton]="false">
      <ng-template pTemplate="content">
        @for (item of 6 | arrayOf; track item) {
          <div class="border-round border-1 surface-border p-4 surface-card">
            <ul class="m-0 p-0 list-none">
              <li class="mb-3">
                <div class="flex">
                  <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                  <div style="flex: 1">
                    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        }
      </ng-template>
    </app-common-page>
  `,
  imports: [
    CommonPageComponent,
    DepartmentsTableComponent,
    SharedModule,
    SkeletonModule,
    ArrayOfPipe,
    AsArrayPipe
  ],
  standalone: true
})
export class LandingPageComponent {
}
