import {Component} from '@angular/core';
import {SkillsTableComponent} from './skills-table.component';
import {CommonPageComponent} from '../../../../../shared/components/page/common-page.component';
import {SharedModule} from 'primeng/api';

@Component({
  selector: 'app-skills-page',
  template: `
    <app-common-page entityName="app.entities.skill.name" onDemandCountMethod="skillsCount" stateKey="skills">
      <ng-template pTemplate="content">
        <app-skills-table></app-skills-table>
      </ng-template>
    </app-common-page>
  `,
  standalone: true,
  imports: [CommonPageComponent, SkillsTableComponent, SharedModule]
})
export class SkillsPageComponent {
}
