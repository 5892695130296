import {Component} from '@angular/core';
import { CommonPageComponent } from '../../shared/components/page/common-page.component';
import { DepartmentsTableComponent } from './app-domain/dictionaries/departments-page.component';
import { SharedModule } from 'primeng/api';
import { SkeletonModule } from 'primeng/skeleton';
import { ArrayOfPipe } from '../../shared/components/array-of.pipe';
import { AsArrayPipe } from '../../shared/components/as-array.pipe';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import {I18nService} from '../../shared/services/i18n/i18n.service';
import {CurrencyPipe, DatePipe, DecimalPipe, PercentPipe} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-settings-page',
  template: `
    <app-common-page entityName="app.settings" [showNewEntityButton]="false">
      <ng-template pTemplate="content">
        <div class="flex align-items-center">
          <span class="p-float-label">
            <p-dropdown [ngModel]="i18n.localeService.currentLocale" [options]="i18n.localeService.availableLocalesOptions" [autoDisplayFirst]="false"
                        (ngModelChange)="i18n.setLocale($event)" [style]="{minWidth: '20rem'}"></p-dropdown>
            <label>{{ 'shared.selectLocale' | translate }}</label>
          </span>
          <div class="flex ml-3 text-lg">
            <span>{{'shared.date' | translate}}:</span><span class="ml-2 mr-3 text-primary">{{ date | date }}</span>
            <span>{{'shared.currency' | translate}}:</span><span class="ml-2 mr-3 text-primary">{{ 1900.25 | currency }}</span>
            <span>{{'shared.number' | translate}}:</span><span class="ml-2 mr-3 text-primary">{{ 1234.567 | number }}</span>
            <span>{{'shared.percent' | translate}}:</span><span class="ml-2 text-primary">{{ 0.15 | percent }}</span>
          </div>
        </div>
      </ng-template>
    </app-common-page>
  `,
  imports: [
    CommonPageComponent,
    SharedModule,
    DropdownModule,
    FormsModule,
    DatePipe,
    CurrencyPipe,
    DecimalPipe,
    PercentPipe,
    TranslateModule
  ],
  standalone: true
})
export class AppSettingsPageComponent {
  date = new Date();
  constructor(public i18n: I18nService) {
  }
}
