import {Component, inject} from '@angular/core';
import { AsyncPipe, NgIf, NgOptimizedImage } from '@angular/common';
import {AuthProviderService} from '../auth-provider';

@Component({
  selector: 'app-user-avatar',
  template: `
    <div class="mt-app-nav-bar-avatar">
      @if (authProvider.user$ | async; as user) {
        @if (!user.avatar) {
          <i class="pi pi-user"></i>
        } @else {
          <img [ngSrc]="user.avatar" style="border-radius: 50%" width="30" height="30" priority>
        }
      }
    </div>
  `,
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    NgOptimizedImage
  ]
})
export class UserAvatarComponent {
  protected authProvider = inject(AuthProviderService);
}
