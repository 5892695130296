import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptorFn,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import { catchError, tap, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { HttpDataConverterService } from './http-data-converter.service';
import { COMMON_LIB_CONFIG_TOKEN, ICommonLibConfig } from '../../common-lib-config';
import { LocaleService } from '../i18n/i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { Alert } from '../../util/alert';

export const IGNORE_HTTP_MESSAGES = new HttpContextToken<Array<number>>(() => []);


export const appHttpInterceptor: HttpInterceptorFn = (request, next) => {

  const dataConverter = inject(HttpDataConverterService);
  const config = inject<ICommonLibConfig>(COMMON_LIB_CONFIG_TOKEN);
  const currentLocale = inject(LocaleService).currentLocale;
  const translate = inject(TranslateService);

  if (request.body && !(request.body instanceof FormData)) {
    request = request.clone({
      body: dataConverter.out(cloneDeep(request.body))
    });
  }

  request = request.clone({
    setHeaders: {
      'x-lang': currentLocale
    },
    url: `${config.apiUrl}${request.url}`
  });

  return next(request).pipe(
    tap((ev: HttpEvent<any>) => {
      if (ev instanceof HttpResponse) {
        dataConverter.in(ev.body);
      }
    }),
    catchError((errorResponse) => {
      if (errorResponse instanceof HttpErrorResponse) {
        const httpErrorResponse = errorResponse as HttpErrorResponse;
        const ignoreMessages = request.context.get(IGNORE_HTTP_MESSAGES);
        if (!ignoreMessages?.includes(httpErrorResponse.status)) {
          const detail = httpErrorResponse.error?.message || httpErrorResponse.message;
          Alert.message({
            severity: 'error',
            summary: translate.instant('shared.messages.responseError'),
            detail,
            life: 10000
          });
        }
      }
      return throwError(() => errorResponse);
    })
  );
};

