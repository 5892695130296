import { importProvidersFrom, NgModule } from '@angular/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { TaasinatorRoutingModule } from './taasinator-routing.module';
import { SharedModule } from '../shared/shared.module';
import { i18nConfig } from './config/i18n-config';
import { commonLibConfig } from './config/common-lib-config';
import { appHttpInterceptor } from '../shared/services/http/app-http-interceptor';
import { environment } from '../../environments/environment';
import {forceResourcesProviders} from './resources/resources-providers';
import { auth0Providers } from './auth-provider/auth0/auth0-auth-provider.module';
import { authHttpInterceptorFn } from '@auth0/auth0-angular';

@NgModule({
  providers: [
    commonLibConfig(),
    i18nConfig(),
    importProvidersFrom(SharedModule, TaasinatorRoutingModule),
    provideHttpClient(withInterceptors([appHttpInterceptor, authHttpInterceptorFn])),
    auth0Providers({ ...environment.authProviderConfig }),
    forceResourcesProviders(),
  ]
})
export class TaasinatorModule {
}
