import { Component, NgModule } from '@angular/core';
import { ActivatedRoute, provideRouter, Routes, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { startCase } from 'lodash';
import { LandingPageComponent } from './components/landing-page.component';
import { pendingChangesGuard } from '../shared/services/route-guards';
import { DepartmentsPageComponent } from './components/app-domain/dictionaries/departments-page.component';
import { RolesPageComponent } from './components/app-domain/dictionaries/roles-page.component';
import { ExpenseCategoriesPageComponent } from './components/app-domain/dictionaries/expense-category-page.component';
import { SkillsPageComponent } from './components/app-domain/dictionaries/skills/skills-page.component';
import { PaidTimeOffsPageComponent } from './components/app-domain/dictionaries/paid-time-offs-page.component';
import {
  HolidayCalendarsPageComponent
} from './components/app-domain/dictionaries/holidays/holiday-calendars-page.component';
import {
  HolidayCalendarEditPageComponent
} from './components/app-domain/dictionaries/holidays/holiday-calendar-edit-page.component';
import {
  HolidaysUniversePageComponent
} from './components/app-domain/dictionaries/holidays/holidays-universe-page.component';
import { WorkweekPageComponent } from './components/app-domain/dictionaries/workweeks/workweek-page.component';
import { SystemRolePageComponent } from './components/app-domain/dictionaries/system-roles-page.component';
import { TestComponent } from './test.component';
import { SkillEditPageComponent } from './components/app-domain/dictionaries/skills/skill-edit-page.component';
import { AppSettingsPageComponent } from './components/app-settings-page.component';
import { authGuardFn } from '@auth0/auth0-angular';
import {OrganizationsPageComponent} from './components/app-domain/organizations/organizations-page.component';
import { permissibleGuardFn } from '../shared/auth-provider/permissions';

@Component({
  selector: 'app-tbd-page',
  template: `
    <h3>TBD</h3>
    <div class="text-lg">{{ id1 }}</div>
    <div class="mt-2">{{ id2 }}</div>
  `,
  standalone: true
})
export class TBDComponent {
  id1?: string | null;
  id2?: string | null;

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.id1 = startCase(params.get('id1') || '');
      this.id2 = startCase(params.get('id2') || '');
    });
  }
}

const routes: Routes = [
  // { path: 'login', component: LoginPageComponent, title: 'Login', canActivate: [] },
  {
    path: '', canActivateChild: [authGuardFn], children: [
      { path: '', redirectTo: '/landing-page', pathMatch: 'full' },
      { path: 'landing-page', component: LandingPageComponent, title: 'Greetings' },

      { path: 'settings/departments', component: DepartmentsPageComponent, title: 'Departments' },
      { path: 'settings/roles', component: RolesPageComponent },
      {
        path: 'settings/expense-categories',
        component: ExpenseCategoriesPageComponent,
        title: 'Expense Categories'
      },
      { path: 'settings/skills', component: SkillsPageComponent, title: 'Skills' },
      {
        path: 'settings/skills/edit',
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        component: SkillEditPageComponent,
        canDeactivate: [pendingChangesGuard],
        title: 'Edit Skill'
      },
      { path: 'settings/paid-time-offs', component: PaidTimeOffsPageComponent, title: 'Paid Time Offs' },
      { path: 'settings/holiday-calendars', component: HolidayCalendarsPageComponent, title: 'Holiday Calendars' },
      {
        path: 'settings/holiday-calendars/edit',
        component: HolidayCalendarEditPageComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        canDeactivate: [pendingChangesGuard],
        title: 'Edit Holiday Calendar'
      },
      { path: 'settings/holidays', component: HolidaysUniversePageComponent, title: 'Holidays' },
      {
        path: 'settings/workweeks',
        component: WorkweekPageComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        canDeactivate: [pendingChangesGuard],
        title: 'Workweeks'
      },
      {
        path: 'settings/system', component: SystemRolePageComponent,
        loadChildren: () => import('./components/app-domain/dictionaries/system-roles-page.component')
      },

      { path: 'app-settings', component: AppSettingsPageComponent, title: 'Settings' },
      { path: 'organizations', component: OrganizationsPageComponent, title: 'Organizations', canActivate: [permissibleGuardFn(' TENANT_ALL')] },

      { path: 'tbd', component: TBDComponent },
      { path: 'tbd/:id1', component: TBDComponent },
      { path: 'tbd/:id1/:id2', component: TBDComponent },

      { path: 'test', component: TestComponent, loadChildren: () => import('./test.component') }
    ]
  },
  { path: '**', redirectTo: '/landing-page', pathMatch: 'full' }

];

@NgModule({
  providers: [
    provideRouter(routes, withComponentInputBinding(), withRouterConfig({ canceledNavigationResolution: 'computed' }))
  ]
})
export class TaasinatorRoutingModule {
}
