import {Component} from '@angular/core';
import {IPaidTimeOff, TPaidTimeOffSave} from '../../../../api/shared/app-domain/dictionaries';
import {inheritanceProvider} from '../../../../shared/util/util';
import {ColumnHeaderComponent} from '../../../../shared/components/table/column-header.component';
import {AbstractEntityEditorBase} from '../../../../shared/components/entity-editor/abstract-entity-editor-base';
import {AbstractEntityEditor} from '../../../../shared/components/entity-editor/abstract-entity-editor';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {TControlsOf} from '../../../../shared/types';
import {PaidTimeOffsResourceService} from '../../../resources/dictionaries/paid-time-offs-resource.service';
import {ConstantsProviderService} from '../../../resources/constants-provider.service';
import {IMenuItem} from '../../../../shared/components/table/table-menus';
import {
  AbstractFieldFormControl,
  FormControlWrapperComponent
} from '../../../../shared/components/entity-editor/form-control-wrapper.component';
import {existsAsyncValidator} from '../../../../shared/util/validators';
import {TableCellComponent} from '../../../../shared/components/table/table-cell.component';
import {SharedModule} from 'primeng/api';
import {EntityEditDialogComponent} from '../../../../shared/components/entity-editor/entity-edit.dialog';
import {CommonPageComponent} from '../../../../shared/components/page/common-page.component';
import {NgIf} from '@angular/common';
import {SpinnerizerDirective} from '../../../../shared/components/spinnerizer.component';
import {
  CommonColumnHeaderIsArchived,
  CommonFormControlIsArchived,
  CommonFormControlName,
  CommonTableCellIsArchived,
  CommonTableCellName
} from '../../common/common-controls-wrappers';
import {EntityTableComponent} from '../../../../shared/components/table/entity-table.component';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-paid-time-off-form-control',
  template: `
    @switch (field) {
      @case ('name') {
        <app-fc-name/>
      }
      @case ('isArchived') {
        <app-fc-isArchived/>
      }
      @case ('days') {
        <app-form-control-wrapper [controlName]="field" [label]="'app.entities.paidTimeOff.fields.days' | translate"
                                  dataType="number" suffix="d" placeholder="&infin;"
                                  [showButtons]="true" [min]="0"></app-form-control-wrapper>
      }
    }
  `,
  standalone: true,
  imports: [CommonFormControlName, CommonFormControlIsArchived, FormControlWrapperComponent, TranslateModule]
})
export class PaidTimeOffFormControlComponent extends AbstractFieldFormControl<TPaidTimeOffSave> {
}

@Component({
  selector: 'app-paid-time-off-editor',
  template: `
    <div [spinnerizer]="loading">
      <form *ngIf="form" [formGroup]="form">
        <div class="formgrid grid">
          <div class="field col-12 p-fluid">
            <app-paid-time-off-form-control field="name"></app-paid-time-off-form-control>
          </div>
          <div class="field col-12 p-fluid">
            <app-paid-time-off-form-control field="days"></app-paid-time-off-form-control>
          </div>
          <div *ngIf="!isNew() && entity!.isCustom" class="field col-12 p-fluid">
            <app-paid-time-off-form-control field="isArchived"></app-paid-time-off-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    inheritanceProvider(AbstractEntityEditorBase, PaidTimeOffEditorComponent)
  ],
  standalone: true,
  imports: [
    NgIf, FormsModule, ReactiveFormsModule, PaidTimeOffFormControlComponent,
    SpinnerizerDirective
  ]
})
export class PaidTimeOffEditorComponent extends AbstractEntityEditor<TPaidTimeOffSave, IPaidTimeOff> {

  constructor(public paidRimeOffsResource: PaidTimeOffsResourceService) {
    super(paidRimeOffsResource, 'app.entities.paidTimeOff.name');
  }

  override buildForm(): void {
    const checkExists = this.getCheckExists(this.paidRimeOffsResource.searchEntities.bind(this.paidRimeOffsResource), 'name');
    this.form = this.fb.group<TControlsOf<TPaidTimeOffSave>>({
      name: new FormControl(this.entity!.name, {
        nonNullable: true,
        validators: [Validators.required],
        asyncValidators: [existsAsyncValidator(checkExists)]
      }),
      days: new FormControl(this.isNew() ? null : this.entity!.days, []),
      isArchived: new FormControl(this.isNew() ? false : this.entity!.isArchived, [Validators.required]),
      isCustom: new FormControl(this.isNew() ? true : this.entity!.isCustom, []),
    });
    if (!this.isNew() && !this.entity!.isCustom) {
      this.setEnable('name', false);
    }
  }
}


@Component({
  selector: 'app-paid-time-offs-table',
  template: `
    <app-entity-edit-dialog #editDialog>
      <ng-template pTemplate let-param>
        <app-paid-time-off-editor [param]="param"></app-paid-time-off-editor>
      </ng-template>
    </app-entity-edit-dialog>

    <app-entity-table #entityTable
                      [api]="paidTimeOffsResource" [defaultSort]="[{field: 'name'}]"
                      [pageable]="false" [omitMenuItems]="['edit']" [customMenuItems]="$any(customMenuItems)"
                      [editDialog]="editDialog">
      <ng-template pTemplate="$header">
        <app-th field="name" [label]="'app.entities.common.fields.isArchived' | translate" filterType="text"></app-th>
        <app-th field="days" [label]="'app.entities.paidTimeOff.fields.days' | translate" filterType="numeric" [fixedWidth]="160"></app-th>
        <app-th-isArchived/>
      </ng-template>
      <ng-template pTemplate="$body" let-rowData>
        <app-td-name [rowData]="rowData" [omitMenuItems]="['duplicate']"
                     [customMenuItems]="$any(customMenuItems)"
                     (onClick)="entityTable.openCreateOrUpdate(rowData)"
                     [imageSrc]="constants.contractTypeIcon(rowData.name)"/>
        <app-td field="days" align="right" [rowData]="rowData" nullSymbol="&infin;"></app-td>
        <app-td-isArchived [rowData]="rowData"/>
      </ng-template>
    </app-entity-table>
  `,
  standalone: true,
  imports: [
    PaidTimeOffEditorComponent,
    SharedModule,
    EntityEditDialogComponent,
    EntityTableComponent,
    ColumnHeaderComponent,
    CommonColumnHeaderIsArchived,
    CommonTableCellName,
    TableCellComponent,
    CommonTableCellIsArchived,
    TranslateModule
  ]
})
export class PaidTimeOffsTableComponent {
  customMenuItems: Array<IMenuItem<IPaidTimeOff>> = [
    {
      id: 'delete', // override
      isEnabled: (param) => param.data.isCustom
    },
    {
      id: 'archive', // override
      isEnabled: (param) => !param.data.isArchived && param.data.isCustom
    }
  ];

  constructor(public paidTimeOffsResource: PaidTimeOffsResourceService,
              public constants: ConstantsProviderService) {
  }
}


@Component({
  selector: 'app-paid-time-offs-page',
  template: `
    <app-common-page entityName="app.entities.paidTimeOff.name" onDemandCountMethod="paidTimeOffsCount">
      <ng-template pTemplate="content">
        <app-paid-time-offs-table></app-paid-time-offs-table>
      </ng-template>
    </app-common-page>
  `,
  standalone: true,
  imports: [
    PaidTimeOffsTableComponent,
    CommonPageComponent,
    SharedModule
  ]
})
export class PaidTimeOffsPageComponent {
}
