import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ICountry} from '../../api/shared/common';
import {OnDemandLoader} from '../../shared/services/resources/on-demand-resource-loader.service';

@Injectable({
  providedIn: 'root'
})
export class CommonResourceService {
  constructor(private http: HttpClient) {
  }

  @OnDemandLoader('countries')
  getCountries(): Observable<Array<ICountry>> {
    return this.http.get<Array<ICountry>>('/api/common/countries');
  }
}
