import {Component} from '@angular/core';
import {IRole, TRoleSave} from '../../../../api/shared/app-domain/dictionaries';
import {inheritanceProvider} from '../../../../shared/util/util';
import {ColumnHeaderComponent} from '../../../../shared/components/table/column-header.component';
import {AbstractEntityEditorBase} from '../../../../shared/components/entity-editor/abstract-entity-editor-base';
import {AbstractEntityEditor} from '../../../../shared/components/entity-editor/abstract-entity-editor';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {TControlsOf} from '../../../../shared/types';
import {RolesResourceService} from '../../../resources/dictionaries/roles-resource.service';
import {AbstractFieldFormControl} from '../../../../shared/components/entity-editor/form-control-wrapper.component';
import {existsAsyncValidator} from '../../../../shared/util/validators';
import {NgClass, NgIf} from '@angular/common';
import {
  CommonColumnHeaderIsArchived,
  CommonFormControlIsArchived,
  CommonFormControlName,
  CommonTableCellIsArchived,
  CommonTableCellName
} from '../../common/common-controls-wrappers';
import {SpinnerizerDirective} from '../../../../shared/components/spinnerizer.component';
import {SharedModule} from 'primeng/api';
import {EntityEditDialogComponent} from '../../../../shared/components/entity-editor/entity-edit.dialog';
import {CommonPageComponent} from '../../../../shared/components/page/common-page.component';
import {EntityTableComponent} from '../../../../shared/components/table/entity-table.component';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-role-form-control',
  template: `
    @switch (field) {
      @case ('name') {
        <app-fc-name/>
      }
      @case ('isArchived') {
        <app-fc-isArchived/>
      }
    }
  `,
  standalone: true,
  imports: [CommonFormControlName, CommonFormControlIsArchived]
})
export class RoleFormControlComponent extends AbstractFieldFormControl<TRoleSave> {
}

@Component({
  selector: 'app-role-editor',
  template: `
    <div [spinnerizer]="loading">
      <form *ngIf="form" #frm [formGroup]="form">
        <div class="formgrid grid">
          <div class="field col-8 p-fluid" [ngClass]="isNew() ? 'col-12' : 'col-8'">
            <app-role-form-control field="name"></app-role-form-control>
          </div>
          <div *ngIf="!isNew()" class="field col-4 p-fluid">
            <app-role-form-control field="isArchived"></app-role-form-control>
          </div>
        </div>
      </form>
    </div>
  `,
  providers: [
    inheritanceProvider(AbstractEntityEditorBase, RoleEditorComponent)
  ],
  standalone: true,
  imports: [
    NgIf, FormsModule, ReactiveFormsModule, NgClass, RoleFormControlComponent,
    SpinnerizerDirective
  ]
})
export class RoleEditorComponent extends AbstractEntityEditor<TRoleSave, IRole> {

  constructor(public rolesResource: RolesResourceService) {
    super(rolesResource, 'app.entities.role.name');
  }


  override buildForm(): void {
    const checkExistsName = this.getCheckExists(this.rolesResource.searchEntities.bind(this.rolesResource), 'name');
    this.form = this.fb.group<TControlsOf<TRoleSave>>({
      name: new FormControl(this.entity!.name, {
        nonNullable: true,
        validators: [Validators.required],
        asyncValidators: [existsAsyncValidator(checkExistsName)]
      }),
      isArchived: new FormControl(this.isNew() ? false : this.entity!.isArchived, [Validators.required]),
    });
  }
}


@Component({
  selector: 'app-roles-table',
  template: `
    <app-entity-edit-dialog #editDialog width="500px">
      <ng-template pTemplate let-param>
        <app-role-editor [param]="param"></app-role-editor>
      </ng-template>
    </app-entity-edit-dialog>
    <app-entity-table #entityTable
                      [api]="rolesResource" [defaultSort]="[{field: 'name'}]"
                      [pageable]="false" [omitMenuItems]="['edit']"
                      [editDialog]="editDialog">
      <ng-template pTemplate="$header">
        <app-th field="name" [label]="'app.entities.common.fields.name' | translate" filterType="text"></app-th>
        <app-th-isArchived/>
      </ng-template>
      <ng-template pTemplate="$body" let-rowData>
        <app-td-name [rowData]="rowData" [omitMenuItems]="['duplicate']"
                     (onClick)="entityTable.openCreateOrUpdate(rowData)"/>
        <app-td-isArchived [rowData]="rowData"/>
      </ng-template>
    </app-entity-table>
  `,
  standalone: true,
  imports: [
    EntityEditDialogComponent,
    RoleEditorComponent,
    EntityTableComponent,
    ColumnHeaderComponent,
    CommonColumnHeaderIsArchived,
    CommonTableCellName,
    CommonTableCellIsArchived,
    SharedModule,
    TranslateModule
  ]
})
export class RolesTableComponent {
  constructor(public rolesResource: RolesResourceService) {
  }
}

@Component({
  selector: 'app-roles-page',
  template: `
    <app-common-page entityName="app.entities.role.name" onDemandCountMethod="rolesCount">
      <ng-template pTemplate="content">
        <app-roles-table></app-roles-table>
      </ng-template>
    </app-common-page>
  `,
  standalone: true,
  imports: [
    CommonPageComponent,
    SharedModule,
    RolesTableComponent
  ]
})
export class RolesPageComponent {
}
