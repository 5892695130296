import {Component, inject} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {AsyncPipe} from '@angular/common';
import {AuthProviderService} from '../auth-provider';

@Component({
  selector: 'app-hi-user',
  standalone: true,
  imports: [
    TranslateModule,
    AsyncPipe
  ],
  template: `
    @if (authProvider.user$ | async; as user) {
      <div>{{ 'app.userMenu.hi' | translate }} {{ user.givenName }}</div>
      <div class="mt-1 text-xs text-gray-500">{{ user.email }}</div>
    }
  `
})
export class HiUserComponent {
  protected authProvider = inject(AuthProviderService);
}
