import { Component } from '@angular/core';
import {CommonPageComponent} from '../../../../shared/components/page/common-page.component';
import {OrganizationsTableComponent} from './organizations-table.component';
import {SharedModule} from 'primeng/api';

@Component({
  selector: 'app-organizations-page',
  template: `
    <app-common-page entityName="app.entities.organization.name" onDemandCountMethod="organizationsCount"
                     stateKey="departments">
      <ng-template pTemplate="content">
        <app-organizations-table/>
      </ng-template>
    </app-common-page>
  `,
  imports: [
    CommonPageComponent,
    OrganizationsTableComponent,
    SharedModule
  ],
  standalone: true
})
export class OrganizationsPageComponent {
}
