import { Provider } from '@angular/core';
import { AuthProviderService, IAuthProviderConfig } from '../../../shared/auth-provider/auth-provider';
import { provideAuth0 } from '@auth0/auth0-angular';
import { Auth0AuthProviderService } from './auth0-auth-provider.service';
import {TitleStrategy} from '@angular/router';
import {PageTitleStrategy} from '../../../shared/auth-provider/page-title-strategy';

export function auth0Providers(config: IAuthProviderConfig): Array<Provider> {
  return [
    provideAuth0({
      clientId: config.clientId,
      domain: config.domain,
      authorizationParams: {
        audience: config.audience,
        redirect_uri: window.location.origin, // config.redirectURL
      },
      httpInterceptor: {
        allowedList: config.httpInterceptorConfig.allowedList
      }
    }),
    {
      provide: AuthProviderService,
      useClass: Auth0AuthProviderService
    },
    { provide: TitleStrategy, useClass: PageTitleStrategy }

  ];
}


