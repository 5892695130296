import { COMMON_ENV, IEnv } from './env';
import { IAuthProviderConfig } from '../app/shared/auth-provider/auth-provider';

const apiUrl = 'https://api.staging.conductor.ai';
const appOrigin = 'https://app.staging.conductor.ai';


const kindeConfig: IAuthProviderConfig = {
  clientId: '1a18ba470a4a473696401a9770007489',
  domain: 'https://account.conductor.ai',
  redirectURL: appOrigin,
  logoutRedirectURL: appOrigin,
  audience: `${apiUrl}/api`,
  httpInterceptorConfig: {
    allowedList: [`${apiUrl}/*`],
  }
};

const auth0Config: IAuthProviderConfig = {
  clientId: '51vebSrTO9BOqPjGZdYHE5Kpq31zqxIS',
  domain: 'dev-84hlbw3fllty2vyk.us.auth0.com',
  redirectURL: appOrigin,
  logoutRedirectURL: appOrigin,
  audience: `${apiUrl}/api`,
  httpInterceptorConfig: {
    allowedList: [`${apiUrl}/*`],
  }
}

export const environment: IEnv = {
  production: false,
  ...COMMON_ENV,
  apiUrl,
  authProviderConfig: auth0Config
};
