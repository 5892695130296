import { Injectable } from '@angular/core';
import { Endpoint, EntityResourceBaseService } from '../../shared/services/resources/entity-resource-base.service';
import {concatMap, delay, filter, Observable, of, switchMap} from 'rxjs';
import {
  IArchivable,
  IAvatar,
  ICountry,
  IIdentified,
  IMailable,
  INamed,
  ITenant,
  TEmbeddedTypeWithPrefix
} from '../../api/shared/common';
import { ISearchRequest, ISearchResponse } from '../../api/shared/search-api';
import { map } from 'rxjs/operators';
import {find, findIndex, random} from 'lodash';
import { CommonResourceService } from './common-resource.service';

export interface IOrganization extends IIdentified, INamed, IAvatar, IMailable, IArchivable, TEmbeddedTypeWithPrefix<Omit<ICountry, 'forceState'>, 'country'> {
  userCount: number;
  displayName?: string;
  useMFA: boolean;
}

export type TOrganizationSave = Omit<IOrganization, 'userCount' | 'countryFlag' | 'countryFlag' | 'countryName' | 'id'>;

@Injectable({
  providedIn: 'root'
})
@Endpoint('/api/organizations')
export class OrganizationsResourceService extends EntityResourceBaseService<any, IOrganization> {
  data!: Array<IOrganization>;

  constructor(public common: CommonResourceService) {
    super();
  }

  override searchEntities(searchRequest: ISearchRequest): Observable<ISearchResponse<IOrganization>> {
    if (this.data) {
      return of({results: this.data, total: this.data.length}).pipe(delay(300));
    }
    return this.common.getCountries().pipe(
      map((countries) => countries.filter((c) => c.code === 'US')[0]),
      concatMap((country) => this.http.get<Array<ITenant>>('/api/common/tenants').pipe(
        map((r) => {
          const sr: ISearchResponse<IOrganization> = {
            total: r.length,
            results: []
          };
          sr.results = r.map((tenant) => ({
            id: tenant.id,
            name: tenant.id,
            displayName: tenant.name,
            avatar: tenant.avatar,
            countryCode: 'US',
            countryName: 'United States',
            countryFlag: country.flag,
            email: `boss@${tenant.id}.com`,
            userCount: random(1, 50),
            isArchived: false,
            useMFA: !!random(0, 1)
          }));
          if (!this.data) {
            this.data = sr.results;
          }
          return sr;
        })
      )));
  }

  override getCount(searchRequest: ISearchRequest): Observable<number> {
    return this.data ? of(this.data.length) : this.http.get<Array<ITenant>>('/api/common/tenants').pipe(
        map((r) => r.length)
      );
  }

  override getEntity(id: string): Observable<IOrganization> {
    return of(find(this.data, {id})!);
  }

  override updateEntity(id: string, data: TOrganizationSave): Observable<IOrganization> {
    return this.getCountry(data.countryCode).pipe(
      concatMap((country) => {
        const idx = findIndex(this.data, {id})!;
        this.data[idx] = {
          ...this.data[idx],
          ...data,
          countryName: country!.name,
          countryFlag: country!.flag,
        };
        return of(this.data[idx]);

      })
    );
  }

  override createEntity(data: TOrganizationSave): Observable<IOrganization> {
    return this.getCountry(data.countryCode).pipe(
      concatMap((country) => {
        const newData = {
          ...data,
          id: random(1000, 9999).toString(),
          countryName: country!.name,
          countryFlag: country!.flag,
          userCount: 0
        };
        return of(newData);
      })
    );
  }

  getCountry(code: string): Observable<ICountry | undefined> {
    return this.common.getCountries().pipe(
      map((countries) => countries.find((c) => c.code === code))
    );
  }
}
