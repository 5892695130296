import {Component, ViewChild} from '@angular/core';
import {ListboxChangeEvent, ListboxModule} from 'primeng/listbox';
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel';
import {SharedModule} from 'primeng/api';

@Component({
  selector: 'app-switch-organization-panel',
  template: `
    <p-overlayPanel #opOrg>
      <p-listbox [options]="getOtherOrgs()" [listStyle]="{ maxHeight: '250px'}" (onChange)="switchOrg($event)">
        <ng-template let-org pTemplate="item">
          <div class="flex align-items-center gap-2" style="min-width: 200px">
            <img [src]="org.avatar" style="width: 24px; height: 24px;"/>
            <div>{{ org.name }}</div>
          </div>
        </ng-template>
      </p-listbox>
    </p-overlayPanel>

  `,
  imports: [
    ListboxModule,
    OverlayPanelModule,
    SharedModule
  ],
  standalone: true
})
export class SwitchOrganizationPanelComponent {
  @ViewChild('opOrg') orgPanel!: OverlayPanel;

  getOtherOrgs(): Array<any> {
    return [];
  }

  canSwitchOrg(): boolean {
    return !!this.getOtherOrgs().length;
  }

  switchOrg(ev: ListboxChangeEvent): void {
    this.orgPanel.hide();
    if (ev) {
      location.href = location.origin + '/' + ev.value.id;
    }
  }

  show(originalEvent: Event): void {
    this.orgPanel.show(originalEvent);
  }
}
