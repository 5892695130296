import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {LocaleService} from '../../../shared/services/i18n/i18n.service';
import {AlertBarComponent} from './alert-bar.component';
import {NavBarComponent} from './nav/nav-bar.component';
import {AuthProviderService} from '../../../shared/auth-provider/auth-provider';
import {AsyncPipe, JsonPipe} from '@angular/common';
import {AppCommonPageLoaderComponent} from './page-loader.component';
import {UserProfileService} from '../../../shared/auth-provider/user-profile.service';

@Component({
  selector: 'app-main-page',
  template: `
    <div style="font-size: 10px;position: fixed; top: 0; right: 10px; color: yellow; z-index: 100">
      INFO: Authenticated: {{ authProvider.isAuthenticated$ | async }}
      Locale: {{ locale.currentLocale }}
      @if (userProfileService.userProfile$ | async; as userInfo) {
        Org: {{ userInfo.organization.name }}
      }
      Perms: {{userProfileService.permissions}}
    </div>
    @if (authProvider.isLoading$ | async) {
      <app-page-loader/>
    } @else {
      <div class="mt-app-main">
        @if (userProfileService.userProfile$ | async; as userInfo) {
          <app-alert-bar/>
          <app-nav-bar/>
          <div class="mt-app-desktop">
            <router-outlet></router-outlet>
          </div>
        }
      </div>
    }
  `,
  standalone: true,
  imports: [
    AlertBarComponent,
    NavBarComponent,
    RouterOutlet,
    AsyncPipe,
    AppCommonPageLoaderComponent,
    JsonPipe
  ]
})
export class MainPageComponent {
  constructor(public locale: LocaleService,
              protected authProvider: AuthProviderService,
              protected userProfileService: UserProfileService) {
    userProfileService.authorize();
  }
}

